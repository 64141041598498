import React, {useContext, useState, useEffect, useLayoutEffect} from 'react'
import {GlobalContext} from '../../../state'
import tokenFromLS from '../../../utils/tokenFromLS'
import {getExchangeFromAccount, useFetchAccount} from '../../../hooks/useFetchAccount'
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import InputField from '../../EditFields/Input/InputField'
import Select from '../../EditFields/Select/Select'
import {getSymbolBase, getSymbolQuote} from '../../../utils/symbol'
import {
  buildAccountOptions,
} from '../../../hooks/useFetchAccount'
import tokenfromLS from "../../../utils/tokenFromLS";
import {useNavigate} from "react-router-dom";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import {
  sanitizeSmartBotData,
} from '../../../utils/sanatizer'
import useFetcher from "../../../hooks/useFetcher";
import CryptoWidget from "../../Widget/CryptoWidget";
// import {AdvancedChart} from 'react-tradingview-embed';
import {useParams} from "react-router-dom";

// Read the todo on line 170!
import {widget} from 'charting_library'
// import {UDFCompatibleDatafeed} from 'charting_library/datafeeds/udf/lib/udf-compatible-datafeed'
// import {UDFCompatibleDatafeed} from 'charting_library/datafeeds/udf'


// This was not used at the moment so I commented the import! 
// import {UDFCompatibleDatafeed} from 'charting_library/datafeeds/udf/lib/udf-compatible-datafeed'

// import * as Datafeeds from "";
// "../../../../build/datafeeds/udf/lib/udf-compatible-datafeed";

// Where does this import point to? There is no datafeed folder in utils.
import Datafeed from './../../../utils/datafeed/datafeed.js';
import Modal, {useModal} from "../../Modal/Modal";
import {isNaN, isObject} from "@amcharts/amcharts5/.internal/core/util/Type";
import {parse} from "react-tabulator/lib/editors/DateEditorUtils";
import CBApi from "../../../utils/datafeed/CBApi";
import CBElements from "../../Containers/CBElements";


const SmartbotDetailPage = () => {
  const globalState = useContext(GlobalContext)
  const {openModal} = useModal();

  let params = useParams();
  let id = params.id
  const backtestId = params.backtestId ?? null
  const BacktestActive = backtestId !== null

  // const [botData, setBotData] = useFetchSmartbot(id)
  let url = `smartbot/${id}`
  if (BacktestActive) {
    url = `backtest/${backtestId}/smartbot/${id}`
  }
  const [botData, setBotData] = useFetcher(
    url,
    'GET',
    sanitizeSmartBotData
  )

  const [formSubmitMsg, setformSubmitMsg] = useState(null)
  const [showModalAddFunds, setShowModalAddFunds] = useState(false)
  const [addFunds, setAddFunds] = useState([])
  const [Futures, setFutures] = useState(false)
  const [eventSelectItem, setEventSelectItem] = useState(null)
  const [eventSelectIndicator, setEventSelectIndicator] = useState(null)

  const navigate = useNavigate();

  const _DefaultTimeFrame = "1";

  // const [botData, setBotData] = useFetcher(
  //   `gridbot/${id}`,
  //   'GET',
  //   sanitizeGridbotData
  // )

  const [accountData] = useFetchAccount()
  // const [accountData, setAccountData] = useFetcher(
  //   `account/${botData?.account_id}/market_pairs`,
  //   'GET',
  // )

  //(const [symbolData] = useFetchSymbols(botData.account_id)
  const [symbols, setSymbols] = useState([])
  const [lastTrades, setLastTrades] = useState([]);
  const [activeChart, setActiveChart] = useState(null)
  const [changeRangeInTV, setChangeRangeInTV] = useState(null)

  function getSymbols(accountId) {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/account/${accountId}/market_pairs`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()
  }

  const Datafeedxxx = {
    onReady: (callback) => {
      setTimeout(() => callback({supported_resolutions: ["1", "5", "15", "30", "60", "1D", "1W", "1M"]}), 0);
    },
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
      // console.log('===== searchSymbols running')
    },
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
      // console.log('resolveSymbol', symbolName)
      // expects a symbolInfo object in response
      const split_data = symbolName.split(/[:/]/);
      const symbol_stub = {
        ticker: 'BTCUSD',
        name: 'BTCUSD',
        description: 'Bitcoin/USD',
        type: 'symbolItem.type',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: 'Example Exchange',
        minmov: 1,
        pricescale: 100,
        has_intraday: false,
        visible_plots_set: 'ohlcv',
        has_weekly_and_monthly: false,
        supported_resolutions: ['1', '5', '30', '60', '1D', '1W'],
        volume_precision: 2,
        data_status: 'streaming',
        supports_marks: true,
        // name: symbolName,
        // description: '',
        // type: 'crypto',
        // session: '24x7',
        // timezone: 'Etc/UTC',
        // ticker: symbolName,
        // exchange: split_data[0],
        // minmov: 1,
        // pricescale: 100000000,
        // has_intraday: true,
        // intraday_multipliers: ['1', '60'],
        // supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'],
        // volume_precision: 8,
        // data_status: 'streaming',
      };
      if (split_data[1] === 'USD') {
        symbol_stub.pricescale = 100
      }
      setTimeout(function () {
        onSymbolResolvedCallback(symbol_stub)
      }, 0)

    }
  }

  function sendOrder(type, id) {
    const botId = botData._id
    console.log('sendOrder', type, id, botId)
    console.log('botData', botData)
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/smartbot/${botId}/order/${type}/${id}`
    const fetchOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()

  }

  function getBotLastTrades(botId, from = 0, to = 0) {
    let resource = `smartbot/${botId}/order/tv`
    if (BacktestActive) {
      resource = `backtest/${backtestId}/smartbot/${botId}/order/tv`
    }
    const params = {
      // per_page: 500
    }
    if (from > 0) {
      params.from = from
    }
    if (to > 0) {
      params.to = to
    }
    return CBApi.fetch(resource, 'POST', params)
  }

  function TimeFrameCB2TV(timeFrame) {
    if (timeFrame === '1m') {
      return '1'
    }
    if (timeFrame === '5m') {
      return '5'
    }
    if (timeFrame === '15m') {
      return '15'
    }
    if (timeFrame === '1h') {
      return '60'
    }
    if (timeFrame === '4h') {
      return '240'
    }
    if (timeFrame === '1D') {
      return 'D'
    }
    if (timeFrame === '3d') {
      return '3D'
    }
    if (timeFrame === '1w') {
      return 'W'
    }
    if (timeFrame === '2w') {
      return '2W'
    }
    if (timeFrame === '1M') {
      return 'M'
    }
    return timeFrame
  }

  const getTvChart = () => {
    if (!accountData || accountData.length === 0 || !botData || !botData.account_id) {
      return null
    }
    const exchange = getExchangeFromAccount(accountData, botData.account_id, 'type')
    let symbol = `${exchange}:${botData.symbol}`

    // get First Element of accountData for userId
    const userId = accountData[0].user_id || 'none'

    const widgetOptions = {
      debug: false,
      autosize: true,
      // datafeed: '${Datafeed}',
      // datafeed: new UDFCompatibleDatafeed("https://devapi.bot.nwan.de/tvapi"),
      // datafeed: new UDFCompatibleDatafeed("https://demo_feed.tradingview.com"),
      datafeed: Datafeed,
      charts_storage_api_version: "1.1",
      charts_storage_url: "https://saveload.tradingview.com",
      client_id: "tradingview.com",
      container: "tv_chart_container",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "symbol_search_hot_key",
        "header_compare",
      ],
      enabled_features: [
        "fix_left_edge",
      ],
      fullscreen: false,
      // interval: "5",
      interval: _DefaultTimeFrame,
      // interval: "D",

      // interval: "1h",
      // library_path: "../../charting_library/",
      library_path: "/charting_library/",
      locale: "en",
      studies_overrides: undefined,
      symbol: symbol,
      theme: "Dark",
      timezone: "Etc/UTC",
      user_id: "xyv-cb-" + userId,
      // overrides: {
      //   "mainSeriesProperties.style": 8,
      // },
      custom_indicators_getter: function (PineJS) {
        return Promise.resolve([
          {
            name: "CB Relative Strength Index",
            metainfo: {
              _metainfoVersion: 52,
              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: "#7E57C2"
                  },
                  smoothedMA: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1
                  }
                },
                bands: [{
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: true,
                  value: 70
                }, {
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 30
                }],
                filledAreasStyle: {
                  fill_0: {
                    color: "#7E57C2",
                    transparency: 90,
                    visible: false
                  }
                },
                inputs: {
                  TimeFrame: _DefaultTimeFrame,
                  length: 14,
                  smoothingLine: "SMA",
                  smoothingLength: 14,
                }
              },
              plots: [{
                id: "plot_0",
                type: "line"
              }, {
                id: "smoothedMA",
                type: "line"
              }],
              styles: {
                plot_0: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                },
                smoothedMA: {
                  title: "Smoothed MA",
                  histogramBase: 0,
                  joinPoints: !1
                }
              },
              bands: [{
                id: "hline_0",
                name: "UpperLimit"
              }, {
                id: "hline_1",
                name: "LowerLimit"
              }],
              filledAreas: [{
                id: "fill_0",
                objAId: "hline_0",
                objBId: "hline_1",
                type: "hline_hline",
                title: "Hlines Background"
              }],
              inputs: [{
                id: "TimeFrame",
                name: "TimeFrame",
                defval: _DefaultTimeFrame,
                type: "resolution",
              }, {
                id: "length",
                name: "Length",
                defval: 14,
                type: "integer",
                min: 1,
                max: 2e3
              }, {
                id: "smoothingLine",
                name: "Smoothing Line",
                defval: "SMA",
                type: "text",
                options: ["SMA", "EMA", "WMA"]
              }, {
                id: "smoothingLength",
                name: "Smoothing Length",
                defval: 14,
                type: "integer",
                min: 1,
                max: 1e4
              }],
              id: "CB Relative Strength Index@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB Relative Strength Index",
              description: " CB Relative Strength Index",
              shortDescription: "RSI",
              is_price_study: false,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,
              format: {
                precision: 2,
                type: "price"
              },


            },
            constructor: function () {
              this.f_0 = function (e) {
                return PineJS.Std.max(e, 0)
              }
              this.f_1 = function (e) {
                return -PineJS.Std.min(e, 0)
              }
              this.f_2 = function (e, t) {
                return PineJS.Std.eq(e, 0) ? 100 : PineJS.Std.eq(t, 0) ? 0 : 100 - 100 / (1 + t / e)
              }
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }
              this.main = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback;

                // Change TimeFrame
                if (this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1)
                }

                var i = PineJS.Std.close(this._context)
                  , s = this._input(1)
                  , n = this._input(2)
                  , o = this._input(3);

                // this._context.setMinimumAdditionalDepth(s + o);
                var a, l = this._context.new_var(i), c = PineJS.Std.change(l), h = this.f_0(c),
                  d = this._context.new_var(h), u = PineJS.Std.rma(d, s, this._context), p = this.f_1(c),
                  _ = this._context.new_var(p), m = PineJS.Std.rma(_, s, this._context), g = this.f_2(m, u),
                  f = this._context.new_var(g);
                return "EMA" === n ? a = PineJS.Std.ema(f, o, this._context) : "WMA" === n ? a = PineJS.Std.wma(f, o, this._context) : "SMA" === n && (a = PineJS.Std.sma(f, o, this._context)),
                  [{
                    value: g
                  }, {
                    value: a
                  }]
              }
            }
          },
          {
            name: "CB Average True Range",
            metainfo: {
              _metainfoVersion: 52,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,

              isMTFGaps: 1,
              isMTFResolution: 1,

              description: " CB Average True Range",
              shortDescription: "ATR",
              is_price_study: !1,

              id: " CB Average True Range@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB Average True Range",
              format: {
                type: "inherit"
              },

              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: "#7E57C2"
                  }
                },
                inputs: {
                  length: 14,
                  multiplier: 1,
                },
                bands: [{
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 70
                }, {
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 30
                }],
                filledAreasStyle: {
                  fill_0: {
                    color: "#7E57C2",
                    transparency: 90,
                    visible: false
                  }
                }
              },
              plots: [{
                id: "plot_0",
                type: "line"
              }],
              styles: {
                plot_0: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                }
              },
              bands: [{
                id: "hline_0",
                name: "UpperLimit"
              }, {
                id: "hline_1",
                name: "LowerLimit"
              }],
              filledAreas: [{
                id: "fill_0",
                objAId: "hline_0",
                objBId: "hline_1",
                type: "hline_hline",
                title: "Hlines Background"
              }],
              inputs: [{
                id: "TimeFrame",
                name: "TimeFrame",
                defval: _DefaultTimeFrame,
                type: "resolution",
              }, {
                id: "length",
                name: "length",
                defval: 14,
                type: "integer",
                min: 1,
                max: 2e3
              }, {
                id: "multiplier",
                name: "multiplier",
                defval: 1,
                type: "float",
                min: 1,
                max: 2e3
              }],
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }
              this.main = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback;

                // Change TimeFrame
                if (this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1)
                }

                var o = this._input(1)
                  , multiplier = this._input(2)
                  , s = PineJS.Std.atr(o, context);
                return [s * multiplier]
                // return e && e(s, r),
                //   [s]
              }
            }
          },
          {
            name: "CB EMA Cross",
            metainfo: {
              _metainfoVersion: 52,
              isTVScript: !1,
              isTVScriptStub: !1,
              is_hidden_study: !1,

              isMTFGaps: 1,
              isMTFResolution: 1,

              description: " CB EMA Cross",
              shortDescription: "EMA Cross",
              is_price_study: true,

              id: " CB EMA Cross@tv-basicstudies-1",
              scriptIdPart: "",
              name: " CB EMA Cross",
              format: {
                type: "inherit"
              },

              defaults: {
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: "#FF6D00"
                  },
                  plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: "#43A047"
                  },
                  plot_2: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 3,
                    trackPrice: !1,
                    transparency: 0,
                    visible: true,
                    color: "#2196F3"
                  }
                },
                inputs: {
                  TimeFrame: "1h",
                  length1: 7,
                  length2: 28
                },
                bands: [{
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 70
                }, {
                  color: "#787B86",
                  linestyle: 2,
                  linewidth: 1,
                  visible: false,
                  value: 30
                }],
                filledAreasStyle: {
                  fill_0: {
                    color: "#7E57C2",
                    transparency: 90,
                    visible: false
                  }
                }
              },
              plots: [
                {
                  id: "plot_0",
                  type: "line"
                },
                {
                  id: "plot_1",
                  type: "line"
                },
                {
                  id: "plot_2",
                  type: "line"
                },
              ],
              styles: {
                plot_0: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                },
                plot_1: {
                  title: "Plot",
                  histogramBase: 0,
                  joinPoints: !1
                },
                plot_2: {
                  title: "Crosses",
                  histogramBase: 0,
                  joinPoints: !1
                },
              },
              bands: [{
                id: "hline_0",
                name: "UpperLimit"
              }, {
                id: "hline_1",
                name: "LowerLimit"
              }],
              filledAreas: [{
                id: "fill_0",
                objAId: "hline_0",
                objBId: "hline_1",
                type: "hline_hline",
                title: "Hlines Background"
              }],
              inputs: [
                {
                  id: "TimeFrame",
                  name: "TimeFrame",
                  defval: "1m",
                  type: "resolution"
                },
                {
                  id: "length1",
                  name: "EMA Length 1",
                  defval: 7,
                  type: "integer",
                  min: 1,
                  max: 2000
                },
                {
                  id: "length2",
                  name: "EMA Length 2",
                  defval: 28,
                  type: "integer",
                  min: 1,
                  max: 2000
                }
              ]
            },
            constructor: function () {
              this.init = function (context, inputCallback) {
                this._context = context
                this._input = inputCallback

                // // Change TimeFrame
                this._timeframe = this._input(0)

                if (this._context.symbol.interval !== this._timeframe) {
                  this._context.new_sym(this._context.symbol.tickerid, this._timeframe)
                }

              }

              this.f_0 = function (e, t) {
                return e ? t : PineJS.Std.na()
              }

              this.main = function (context, inputCallback) {
                this._context = context;
                this._input = inputCallback;

                if (this._context.symbol.interval !== this._timeframe) {
                  this._context.select_sym(1);
                }

                var close = PineJS.Std.close(this._context);
                var closeSeries = this._context.new_var(close);
                var length1 = this._input(1);
                var length2 = this._input(2);

                var ema1 = PineJS.Std.ema(closeSeries, length1, this._context);
                var ema2 = PineJS.Std.ema(closeSeries, length2, this._context);

                var cross = PineJS.Std.cross(ema1, ema2, this._context);

                return [
                  ema1, ema2, this.f_0(cross, ema1)
                ]
              }
            }
          },

        ]);
      }
    };

    /**
     * @todo add the 'charting_library' back into this component
     * to make the widget work again.
     */

    const tvWidget = new widget(widgetOptions);
    window.tvWidget = tvWidget;
    tvWidget.onChartReady(() => {

      // Fetch TradingView Lines from the api and ask a function:
      const apiHost = process.env.REACT_APP_API_URI
      const resource = `${apiHost}/smartbot/${id}/cryptowidget`
      const fetchOptions = {
        headers: {
          Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
        },
      }
      const fetchData = async () => {
        try {
          const response = await fetch(resource, fetchOptions)
          return await response.json()
        } catch (error) {
          console.log('error', error)
          return null
        }
      }
      fetchData().then((data) => {
        // console.log('fetchData', data)
        const _current_strategy = data && data.current_strategy ? data.current_strategy : 'long'
        const _current_price = data && data.current_price ? data.current_price : 0.0
        const _current_price_average = data && data.current_price_average ? data.current_price_average : 0.0
        const _current_unrealized_profit = data && data.current_unrealized_profit ? _current_strategy === 'long' ? data.current_unrealized_profit : -data.current_unrealized_profit : 0.0
        const _current_amount_total = data && data.current_amount_total ? _current_strategy === 'long' ? data.current_amount_total : -data.current_amount_total : "0"
        const _current_trailing_price = data && data.current_trailing_price ? data.current_trailing_price : 0.0
        let _current_price_color = 'rgb(237, 85, 101)'
        if (_current_price > _current_price_average) {
          _current_price_color = 'rgb(26, 179, 148)'
        }

        if (!tvWidget) {
          return
        }
        const ActiveChart = tvWidget.activeChart() || null

        if (!ActiveChart) {
          return
        }

        tvWidget.activeChart().onVisibleRangeChanged().subscribe(null, function (range) {
          // console.log('range', range)
          // console.log('range', range.from, range.to)
          // get all past trades:
          // getBotLastTrades(botData._id, range.from, range.to).then(result => setLastTrades(result));
          setChangeRangeInTV(range)
        })

        tvWidget.activeChart().onSymbolChanged().subscribe(null, function (symbol) {
          setChangeRangeInTV(null)
          getBotLastTrades(botData._id).then(result => setLastTrades(result));
        })

        tvWidget.activeChart().onIntervalChanged().subscribe(null, function (interval) {
          setChangeRangeInTV(null)
          getBotLastTrades(botData._id).then(result => setLastTrades(result));
        })

        // Current price line:
        if (ActiveChart && _current_price_average !== 0.0) {
          ActiveChart.createPositionLine()
            .setTooltip('Current average price')
            .setText('Average | ' + _current_unrealized_profit.toFixed(2) + " " + getSymbolQuote(botData.symbol))
            .setPrice(_current_price_average)
            // .setQuantity(1)
            .setLineColor(_current_price_color)
            // Line style: 0 - solid, 1 - dashed, 2 - dotted, 3 - large dashed, 4 - large dotted
            .setLineStyle(4)
            .setQuantity(_current_amount_total ?? "0")
        }

        // Trailing SO:
        if (ActiveChart && _current_trailing_price > 0.0) {
          ActiveChart.createOrderLine()
            .setTooltip("Trailing Order\n"
              + "Name: " + data.current_trailing_name + "\n" +
              +"Price: " + _current_trailing_price + "\n")
            .setText('Trailing SO | ' + _current_trailing_price + " " + getSymbolQuote(botData.symbol))
            .setPrice(_current_trailing_price)
            .setLineColor("#FFB0B1")
            .setLineStyle(4)

        }

        // Current buy and sell lines:
        for (let i = 0; i < Object.keys(data ?? []).length - 1; i++) {
          if (isObject(data[i]) && data[i].id) {
            let item = data[i]
            // data.forEach((item) => {
            // console.log('item', item)

            // is Object:
            if (isObject(item) && (item.state === 'created' || item.state === 'waiting')) {
              // "0": {
              //   "id": 1589,
              //     "side": "sell",
              //     "state": "created",
              //     "type": "limit",
              //     "amount": 13.051,
              //     "price": 18.3467,
              //     "name": "bo_tp_0",
              //     "exchange_createdate": "2023-11-06 23:00:30",
              //     "exchange_filled": 0,
              //     "exchange_lasttradedate": "2023-11-06 23:00:30",
              //     "exchange_remaining": 13.051
              // },
              if (item.side === 'sell') {
                tvWidget.activeChart().createOrderLine()
                  // .setTooltip(item.tooltip)
                  // .setModifyTooltip(item.modifytooltip)
                  // .setCancelTooltip(item.canceltooltip)
                  // .onMove(function () {
                  //   console.log('Order line moved')
                  // })
                  // .onModify(function () {
                  //   console.log('Order line modified')
                  // })
                  .onCancel(function () {
                    sendOrder('cancel', item.id)
                  })
                  .setText(item.name)
                  .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
                  .setQuantity(item.amount)
                  .setPrice(item.price)
                  .setLineColor("#00A59A")
                  .setLineStyle(4)
                  .setQuantityTextColor("#9aedeb")
                  .setBodyBackgroundColor("#04605a")
                  .setBodyTextColor("#67fdf5")
                  .setQuantityBackgroundColor("#078078")
                  .setQuantityTextColor("#02eadc")
                  .setCancelButtonBackgroundColor("#04605a")
                  .setCancelButtonIconColor("#02eadc")
                  .setBodyBorderColor("#10d6c8")
                  .setQuantityBorderColor("#10d6c8")
                  .setCancelButtonBorderColor("#10d6c8")
              }
              if (item.side === 'buy') {
                tvWidget.activeChart().createOrderLine()
                  // .setTooltip(item.tooltip)
                  .onCancel(function () {
                    sendOrder('cancel', item.id)
                  })
                  .setText(item.name)
                  .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
                  .setQuantity(item.amount)
                  .setPrice(item.price)
                  .setLineColor("#4181BE")
                  .setLineStyle(4)
                  .setQuantityTextColor("#9ab4ed")
                  .setBodyBackgroundColor("#0a3b6b")
                  .setBodyTextColor("#6e8fd6")
                  .setQuantityBackgroundColor("#0a3b6b")
                  .setQuantityTextColor("#6e8fd6")
                  .setCancelButtonBackgroundColor("#0a3b6b")
                  .setCancelButtonIconColor("#6e8fd6")
                  .setBodyBorderColor("#6e8fd6")
                  .setQuantityBorderColor("#6e8fd6")
                  .setCancelButtonBorderColor("#6e8fd6")


              }
            }
            // if (isObject(item) && item.state === 'completed') {
            //   // "1": {
            //   //   "id": 1590,
            //   //     "side": "buy",
            //   //     "state": "filled",
            //   //     "type": "limit",
            //   //     "amount": 13.051,
            //   //     "price": 18.3467,
            //   //     "name": "bo_tp_0",
            //   //     "exchange_createdate": "2023-11-06 23:00:30",
            //   //     "exchange_filled": 13.051,
            //   //     "exchange_lasttradedate": "2023-11-06 23:00:30",
            //   //     "exchange_remaining": 0
            //   // },
            //   if (item.side === 'sell') {
            //     tvWidget.activeChart().createOrderLine()
            //       .setTooltip(item.tooltip)
            //       .setText(item.name)
            //       .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
            //       .setQuantity(item.amount)
            //       .setPrice(item.price)
            //       .setLineColor("#00A59A")
            //   }
            //   if (item.side === 'buy') {
            //     tvWidget.activeChart().createOrderLine()
            //       .setTooltip(item.tooltip)
            //       .setText(item.name)
            //       .setTooltip("Side: " + item.side + "\n" + "Amount: " + item.amount + "\n" + "Price: " + item.price + "\n" + "Created: " + item.exchange_createdate)
            //       .setQuantity(item.amount)
            //       .setPrice(item.price)
            //       .setLineColor("#4181BE")
            //   }
            // }
          }
        }
      })

      setActiveChart(tvWidget.activeChart())

      // get all past trades:
      getBotLastTrades(botData._id).then(result => setLastTrades(result));

      // tvWidget.activeChart().createOrderLine()
      //   .setTooltip('My order')
      //   // .setModifyTooltip('Modify my order')
      //   .setCancelTooltip('Cancel my order')
      //   .onMove(function () {
      //     console.log('Order line moved')
      //     return false
      //   })
      //   // .onModify(function () {
      //   //   console.log('Order line modified')
      //   // })
      //   .onCancel(function () {
      //     console.log('Order line canceled')
      //   })
      //   .setText('Text on Order')
      //   .setQuantity("2");
      // tvWidget.activeChart().createPositionLine()
      //   .setTooltip('My position')
      //   .setText('Text on Position')
      //   // .setPrice()
      //    .setQuantity("23");
      //
      // 47860: (e,t,n)=>{
      //   n.d(t, {
      //     g: ()=>i,
      //     i: ()=>f
      //   });
      //   var r = n(28663)
      //     , o = n(79872)
      //     , a = {
      //     Dark: {
      //       "body-background-color": "rgba(24, 33, 41, 0.6)",
      //       "quantity-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-text-color": "rgb(255, 255, 255)",
      //       "sl-quantity-background-color": "#EE7D8B",
      //       "tp-quantity-background-color": "#00A59A",
      //       "buy-quantity-background-color": "#1E72D2",
      //       "sl-line-color": "#DFAD70",
      //       "tp-line-color": "#50B095",
      //       "buy-line-color": "#4181BE",
      //       "pending-line-color": "#929da8"
      //     },
      //     Light: {
      //       "body-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-background-color": "rgba(255, 255, 255, 0.75)",
      //       "quantity-text-color": "rgb(255, 255, 255)",
      //       "sl-quantity-background-color": "#EE7D8B",
      //       "tp-quantity-background-color": "#00A59A",
      //       "sl-line-color": "#E8AC65",
      //       "tp-line-color": "#50B095",
      //       "buy-line-color": "#4181BE",
      //       "pending-line-color": "#929da8"
      //     }
      //   }
      // , u = function(e, t) {
      //     return "BUY" === e ? "Light" === t ? "#06634f" : "#00ffd1" : "Light" === t ? "#870101" : "#FFB0B1"

      // , s = function(e, t) {
      //     var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "Light"
      //       , r = (null == t ? void 0 : t.iconColor) || u(t.side, n)
      //       , o = (null == t ? void 0 : t.iconSize) || 15
      //       , a = (null == t ? void 0 : t.icon) || ("BUY" === t.side ? 61656 : 61655);
      //     return {
      //       type: "mark",
      //       value: e.createShape({
      //         time: t.execution_time,
      //         price: Number(t.price)
      //       }, {
      //         shape: "icon",
      //         icon: a,
      //         zOrder: "top",
      //         overrides: {
      //           size: o,
      //           color: r
      //         }
      //       })
      //     }
      //   }
      // // , l = function(e, t) {
      //     var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "Light"
      //       , o = e.createOrderLine()
      //       , a = t.options
      //       , i = void 0 === a ? {} : a
      //       , u = i.lineHeight
      //       , s = i.bodyBorderColor
      //       , l = i.bodyTextColor
      //       , f = i.lineColor
      //       , b = i.quantityBorderColor
      //       , p = i.quantityTextColor
      //       , d = i.quantityBackgroundColor
      //       , y = i.cancelButtonBorderColor
      //       , m = i.cancelButtonIconColor
      //       , v = i.cancelButtonBackgroundColor
      //       , h = i.bodyBackgroundColor
      //       , g = i.lineStyle
      //       , w = i.hidePriceLabel;
      //     t.editable && (o.onMove(function() {
      //       t.onMove(this.getPrice(), o)
      //     }).onModify("onModify called", function() {}),
      //     t.onCancel && o.onCancel(t.onCancel)),
      //     void 0 !== w && w && (o._line.customization.forcePriceAxisLabel = !1);
      //     var A = c(n);
      //     return o.setPrice(t.price).setText(t.text).setQuantity(t.quantity).setLineStyle(g || 0).setQuantityBackgroundColor(d || A("quantity-background-color")).setQuantityTextColor(p || A("quantity-text-color")).setBodyBackgroundColor(h || A("body-background-color")),
      //       t.type === r.U_.Sl ? o.setLineLength(u || 7).setBodyBorderColor(s || A("sl-line-color")).setBodyTextColor(l || A("sl-line-color")).setLineColor(f || A("sl-line-color")).setQuantityBackgroundColor(A("sl-quantity-background-color")).setQuantityBorderColor(A("sl-quantity-background-color")).setCancelButtonBorderColor(y || A("sl-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("sl-line-color")) : t.type === r.U_.Tp || t.type === r.U_.Mp ? o.setLineLength(7).setBodyBorderColor(s || A("tp-line-color")).setBodyTextColor(l || A("tp-line-color")).setLineColor(f || A("tp-line-color")).setQuantityBackgroundColor(A("tp-quantity-background-color")).setQuantityBorderColor(A("tp-quantity-background-color")).setCancelButtonBorderColor(y || A("tp-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("tp-line-color")) : t.type === r.U_.Buy ? o.setLineLength(u || 7).setBodyBorderColor(b || A("buy-line-color")).setBodyTextColor(l || A("buy-line-color")).setLineColor(f || A("buy-line-color")).setQuantityBackgroundColor(A("buy-quantity-background-color")).setQuantityBorderColor(A("buy-quantity-background-color")).setCancelButtonBorderColor(y || A("buy-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("buy-line-color")) : t.type === r.U_.Pnd && o.setLineLength(u || 7).setBodyBorderColor(s || A("pending-line-color")).setBodyTextColor(l || A("pending-line-color")).setLineColor(f || A("pending-line-color")).setQuantityBorderColor(b || A("pending-line-color")).setCancelButtonBorderColor(y || A("pending-line-color")).setCancelButtonBackgroundColor(v || A("body-background-color")).setCancelButtonIconColor(m || A("pending-line-color")),
      //     t.trailing && o.setLineStyle(2),
      //       {
      //         type: "orderLine",
      //         value: o
      //       }
      //   }
      //     , f = function(e, t) {
      //     try {
      //       "mark" === e.type ? t.removeEntity(e.value) : e.value.remove()
      //     } catch (e) {}
      //   }

    })
  };

  useEffect(() => {
    // Chart active?
    if (!activeChart) {
      return
    }

    // Data exists?
    if (!botData) {
      return
    }

    // Chart loaded and botData exists:
    // if (!activeChart.canCreateStudy())


    function CreateOrChangeStudy(activeChart, s, b, b2, Inputs, Overrides, data) {
      // Check if the study already exist:
      if (data?.studyId) {
        let study = null
        try {
          study = activeChart.getStudyById(data?.studyId)
        } catch (error) {
          study = null
        }
        if (study) {
          // Change the study:
          let NewInputs = study.getInputValues()
          // NewInputs:
          // [
          //     {
          //         "id": "length",
          //         "value": 14
          //     },
          //     {
          //         "id": "smoothingLine",
          //         "value": "SMA"
          //     },
          //     {
          //         "id": "smoothingLength",
          //         "value": 14
          //     },
          //     null,
          //     null
          // ]

          // Change the values:
          // Inputs.forEach((input) => {
          //   NewInputs.forEach((newInput) => {
          //     if (newInput.id === input.id) {
          //       newInput.value = input.value
          //     }
          //   })
          // })
          NewInputs = Inputs
          // console.log('Inputs', Inputs)
          // console.log('NewInputs', NewInputs)

          study.setInputValues(NewInputs)

          // Overrides:
          study.applyOverrides(Overrides)

          return
        }
      }
      // Create the study:
      // activeChart.createStudy(s, b, b2, Inputs)
      try {
        let NewInputs = {}
        Inputs.forEach((input) => {
          NewInputs[input.id] = input.value
        })
        const ActiveChartStudyID = activeChart.createStudy(s, b, b2, NewInputs, Overrides)
        // Save Id:
        ActiveChartStudyID.then((studyId) => {
          data.studyId = studyId
        })
      } catch (error) {
        console.log('error', error)
      }
    }

    // Events exists?
    if (botData && botData._id && botData?.events) {
      // Move over all events:
      botData.events.forEach((event) => {
        // console.log('event', event)

        // Move over all data entrys:
        event.data.forEach((data) => {

          // RSI
          if (data?.name === 'rsi') {
            // Activate RSI in TV:
            const Inputs = [
              {
                id: "TimeFrame",
                value: TimeFrameCB2TV(data.timeframe) ?? _DefaultTimeFrame
              },
              {
                id: "length",
                value: data.length ?? 14
              },
            ]
            let Overrides = {}
            if (data?.condition && (data?.condition === '<=' || data?.condition === '<')) {
              Overrides = {
                "upperlimit.value": parseInt(1000000),
                "upperlimit.visible": false,
                "lowerlimit.value": parseInt(data.value ?? 70),
                "lowerlimit.visible": true,
                "Hlines Background.visible": true,
              }
            }
            if (data?.condition && (data?.condition === '>=' || data?.condition === '>')) {
              Overrides = {
                "upperlimit.value": parseInt(data.value ?? 30),
                "upperlimit.visible": true,
                "lowerlimit.value": parseInt(0),
                "lowerlimit.visible": false,
                "Hlines Background.visible": true,
              }
            }

            CreateOrChangeStudy(activeChart, ' CB Relative Strength Index', false, false, Inputs, Overrides, data)
          }

          // ATR
          if (data?.name === 'atr') {
            // Activate ATR in TV:
            const Inputs = [
              {
                id: "TimeFrame",
                value: TimeFrameCB2TV(data.timeframe) ?? _DefaultTimeFrame,
              },
              {
                id: "length",
                value: parseInt(data.length ?? 14),
              },
              {
                id: "multiplier",
                value: parseFloat(data.multiplier ?? 1),
              }
            ]
            let Overrides = {}
            // if (data?.condition && (data?.condition === '<=' || data?.condition === '<')) {
            //   Overrides = {
            //     "upperlimit.value": parseInt(1000000),
            //     "upperlimit.visible": false,
            //     "lowerlimit.value": parseInt(data.value ?? 70),
            //     "lowerlimit.visible": true,
            //     "Hlines Background.visible": true,
            //   }
            // }
            // if (data?.condition && (data?.condition === '>=' || data?.condition === '>')) {
            //   Overrides = {
            //     "upperlimit.value": parseInt(data.value ?? 30),
            //     "upperlimit.visible": true,
            //     "lowerlimit.value": parseInt(0),
            //     "lowerlimit.visible": false,
            //     "Hlines Background.visible": true,
            //   }
            // }

            CreateOrChangeStudy(activeChart, ' CB Average True Range', false, false, Inputs, Overrides, data)
          }

          // EMA Cross
          if (data?.name === 'emacross') {
            // Activate EMA Cross in TV:
            const Inputs = [
              {
                id: "TimeFrame",
                value: TimeFrameCB2TV(data.timeframe) ?? _DefaultTimeFrame,
              },
              {
                id: "length1",
                value: parseInt(data.length1 ?? 7),
              },
              {
                id: "length2",
                value: parseInt(data.length2 ?? 28),
              }
            ]
            let Overrides = {}
            // if (data?.condition && (data?.condition === '<=' || data?.condition === '<')) {
            //   Overrides = {
            //     "upperlimit.value": parseInt(1000000),
            //     "upperlimit.visible": false,
            //     "lowerlimit.value": parseInt(data.value ?? 70),
            //     "lowerlimit.visible": true,
            //     "Hlines Background.visible": true,
            //   }
            // }
            // if (data?.condition && (data?.condition === '>=' || data?.condition === '>')) {
            //   Overrides = {
            //     "upperlimit.value": parseInt(data.value ?? 30),
            //     "upperlimit.visible": true,
            //     "lowerlimit.value": parseInt(0),
            //     "lowerlimit.visible": false,
            //     "Hlines Background.visible": true,
            //   }
            // }

            CreateOrChangeStudy(activeChart, ' CB EMA Cross', false, false, Inputs, Overrides, data)
          }

        })
      })
    }
  }, [botData?.events, activeChart]);

  useEffect(() => {
    // Old buy and sell lines:
    const Window = window
    const tvWidget = Window.tvWidget
    if (!tvWidget) {
      return
    }
    if (!activeChart) {
      return
    }
    if (!lastTrades) {
      return
    }
    // console.log(activeChart);
    // console.log(lastTrades);

    if (lastTrades?.status === 'ok') {
      // console.log('lastTrades', lastTrades.data)
      const Strategy = botData.strategy ?? 'long';

      const BuyColor = Strategy === 'long' ? '#4181BE' : '#00A59A';
      const SellColor = Strategy === 'long' ? '#00A59A' : '#4181BE';

      // draw all trades:
      lastTrades.data.forEach((trade) => {
        // console.log('time', new Date(trade.exchange_lasttradedate).getTime() )
        const CurrentTimeStamp = new Date(trade.exchange_lasttradedate).getTime() / 1000 - (new Date().getTimezoneOffset() * 60);

        // check activeChart timestamp and trade timestamp
        // tvWidget.activeChart().getVisibleRange().

        // Check CurrentTimeStamp
        if (!isNaN(CurrentTimeStamp) && activeChart && activeChart.getVisibleRange().from <= CurrentTimeStamp && activeChart.getVisibleRange().to >= CurrentTimeStamp) {

          // Check, if the shape already exist:
          if (trade.ShapeId) {
            if (activeChart.getShapeById(trade.ShapeId)) {
              return
            }
          }

          if (trade.side === 'buy') {
            // Icon:
            trade.ShapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              // shape: 'arrow_up',
              shape: 'icon',
              icon: 62299, // 62299 - f35b - arrow_up
              lock: true,
              showInObjectsTree: false,
              disableSelection: true,
              zOrder: "top",
              // Hex Number:
              overrides: {
                color: BuyColor,
                size: 18,

                text: (trade.name ?? ''),

              }
            })
            //
            // if (EntityId) {
            //   console.log('EntityId', EntityId)
            // }
            // Text:
            activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              shape: 'note',
              text: (trade.name ?? '') + ' Id: ' + trade.id + '\nAverage: ' + trade.exchange_average + ' Filled: ' + trade.exchange_filled,
              lock: true,
              showInObjectsTree: false,
              // disableSelection: true,
              zOrder: "top",
              overrides: {
                markerColor: 'rgba(0,0,0,0)', // Transparent
                backgroundColor: BuyColor,
                backgroundTransparency: 30,
                borderColor: '#000000',
                transparency: 0,
              }
            })

          }
          if (trade.side === 'sell') {
            // Icon:
            trade.ShapeId = activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              // shape: 'arrow_down',
              shape: 'icon',
              icon: 62296, // 62296 - f358 - arrow_down
              lock: true,
              showInObjectsTree: false,
              zOrder: "top",
              overrides: {
                color: SellColor,
                size: 18,
                text: trade.name ?? '',
              }
            })
            // Text:
            activeChart.createShape({
              time: CurrentTimeStamp,
              price: trade.exchange_average,
            }, {
              shape: 'note',
              text: (trade.name ?? '') + ' Id: ' + trade.id + '\nAverage: ' + trade.exchange_average + ' Filled: ' + trade.exchange_filled,
              lock: true,
              showInObjectsTree: false,
              // disableSelection: true,
              zOrder: "top",
              overrides: {
                markerColor: 'rgba(0,0,0,0)', // Transparent
                backgroundColor: SellColor,
                backgroundTransparency: 30,
                borderColor: '#000000',
                transparency: 0,
              }
            })
          }
        }
      })
    }
    // CurrentChart.createShape({
    //   time: new Date().getTime(),
    //   price: 0.4967,
    // }, {
    //   shape: 'arrow_down',
    //   overrides: {
    //     size: 5,
    //     color: '#ff0000',
    //   }
    // })

    //}, [lastTrades, window?.tvWidget])
  }, [lastTrades, activeChart, changeRangeInTV])

  useEffect(() => {
    if (botData && accountData) {
      // initOnReady();
      getTvChart();
    }
  }, [botData?.symbol, accountData]);

  useEffect(() => {
    // console.log('useEffect', botData)
    if (botData && botData.account_id) {
      getSymbols(botData.account_id).then((data) => setSymbols(data))
    }
  }, [botData?.account_id])

  useEffect(() => {
    // Assistent max_quote_amount
    if (botData && botData.symbol) {
      let CurrentPrice = botData.stats?.current_price_bid ?? 0
      let MaxQuoteAmount = 0
      let MAXDCAOrders = botData.DCAMaxSO ?? -1
      let Count = 0
      let LastCoefficintAmount = 0
      let MaxDrawdown = 0
      // for each DCA Order botData.DCAOrders
      if (botData.dcaorders && botData.dcaorders.length) {
        botData.dcaorders.forEach((dcaorder) => {
          if (MAXDCAOrders >= 0) {
            if (Count >= MAXDCAOrders) {
              return
            }
          }
          let AmountTyoe = dcaorder.amounttype ?? 'none'
          // Switch:
          // 1. none
          // 2. quote
          // 3. base
          // 4. coefficient
          switch (AmountTyoe) {
            case 'quote':
              MaxQuoteAmount += dcaorder.amount
              LastCoefficintAmount = dcaorder.amount
              break
            case 'base':
              MaxQuoteAmount += dcaorder.amount * CurrentPrice
              LastCoefficintAmount = dcaorder.amount * CurrentPrice
              break
            case 'coefficient':
              MaxQuoteAmount = (LastCoefficintAmount * dcaorder.amount) + MaxQuoteAmount
              LastCoefficintAmount = LastCoefficintAmount * dcaorder.amount
              break
            case 'walletfreepercent':
              MaxQuoteAmount += (botData.stats?.exchange_balance_free ?? 0) * (dcaorder.amount / 100)
              LastCoefficintAmount = (botData.stats?.exchange_balance_free ?? 0) * (dcaorder.amount / 100)
              break
            case 'wallettotalpercent':
              MaxQuoteAmount += (botData.stats?.exchange_balance_total ?? 0) * (dcaorder.amount / 100)
              LastCoefficintAmount = (botData.stats?.exchange_balance_total ?? 0) * (dcaorder.amount / 100)
              break
            default:
              MaxQuoteAmount = 0
              break
          }

          // MaxDrawdown
          const PriceType = dcaorder.pricetype ?? 'none'
          switch (PriceType) {
            case 'percent':
              MaxDrawdown += dcaorder.price
              break
          }
          Count++
        })
        botData.max_quote_amount = MaxQuoteAmount
        botData.max_drawdown = MaxDrawdown
      }

    }
  }, [botData])

  function mapSymbols(arr) {
    if (arr && arr.length) {
      return arr.map((item) => ({name: item.symbol, value: item.symbol}))
    }
    return []
  }

  function onChangeFirstInput(evt) {
    setBotData(function (prevState) {
      return {
        ...prevState,
        name: evt.target.value,
      }
    })
  }

  function getLimitsFromSymbol(aSymbol) {
    if (!aSymbol) {
      return null
    }
    let ReturnSymbol = {}
    if (symbols.length) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol) {
          ReturnSymbol = item
        }
      })
    }
    return ReturnSymbol
  }

  function getPrecisionFromSymbol(aSymbol) {
    let ReturnSymbol = {
      base: 8,
      quote: 8,
      amount: 0,
      price: 0,
    }
    if (
      symbols &&
      symbols.length &&
      symbols.length > 0 &&
      aSymbol &&
      aSymbol !== ''
    ) {
      symbols.forEach((item) => {
        if (item.symbol === aSymbol && item.precision) {
          ReturnSymbol.base = (item.precision.base ? item.precision.base : ReturnSymbol.base)
          ReturnSymbol.quote = (item.precision.quote ? item.precision.quote : ReturnSymbol.quote)
          ReturnSymbol.amount = (item.precision.amount ? item.precision.amount : ReturnSymbol.amount)
          ReturnSymbol.price = (item.precision.price ? item.precision.price : ReturnSymbol.price)
        }
      })
    }
    // console.log('getPrecisionFromSymbol', ReturnSymbol)
    return ReturnSymbol
  }

  const onSubmitForm = async (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)

    // console.log(evt.target)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)

    // delete data.id
    data = {
      ...data,
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/smartbot/${id}`
      let Method = 'PUT'
      if (id === 'add') {
        RequestURL = `${process.env.REACT_APP_API_URI}/smartbot`
        Method = 'ADD'
        delete data._id
      }
      const response = await fetch(
        RequestURL,
        {
          method: Method,
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )

      const json = await response.json()
      if (json.status === 'ok') {
        navigate('/smartbot');
      } else {
        openModal({
          content: 'Error: ' + (json.error || ' ') + (JSON.stringify(json?.data) || ' ') + (JSON.stringify(json?.error_description) || ' ' + JSON.stringify(json?.message) || ' '),
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        });
      }

    } catch (err) {
      openModal({
        content: 'Error: ' + err,
        showDismissButton: true,
        dismissButtonTxt: 'Close',
        showFooter: true,
      });
    }
  }

  const Precision = getPrecisionFromSymbol(botData ? botData.symbol : '')

  // profitcomplete
  const [ProfitComplete, setProfitComplete] = useState([])

  function getProfitComplete(id) {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/smartbot/${id}/profitcomplete`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        return await response.json()
      } catch (error) {
        console.log('error', error)
      }
    }
    // console.log('botData', botData)
    return fetchData()
  }

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_USD')) {
      return
    }

    let root_stats_trades = am5.Root.new('chart_profit_USD')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_stats_trades.setThemes([
      am5themes_Animated.new(root_stats_trades)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_stats_trades.container.children.push(am5xy.XYChart.new(root_stats_trades, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root_stats_trades.verticalLayout
    }));

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root_stats_trades, {
      orientation: "horizontal"
    }));

    // Chart title
    let title = chart.plotContainer.children.push(am5.Label.new(root_stats_trades, {
      text: "Daily profit in $ by crazybot.io",
      fontSize: 20,
      fontWeight: "400",
      x: am5.p50,
      centerX: am5.p50
    }))

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      // For backwards compatibility
      for (let i = ProfitComplete.stats_daily.length - 1; i >= 0; i--) {
        let dataentry = {
          date: ProfitComplete.stats_daily[i]._id,
          // profit: ProfitComplete.stats_daily[i].profit,
          profit_USD: ProfitComplete.stats_daily[i].profit_USD - ProfitComplete.stats_daily[i].fee_calcUSD,
          // profit_percent: ProfitComplete.stats_daily[i].profit_percent,
          // count: ProfitComplete.stats_daily[i].count,
          fee_calcUSD: ProfitComplete.stats_daily[i].fee_calcUSD,
        }
        data.push(dataentry)
      }
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root_stats_trades, {});
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root_stats_trades, {
      categoryField: "date",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root_stats_trades, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    })

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root_stats_trades, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(root_stats_trades, {
        strokeOpacity: 0.1
      })
    }));

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(am5.Legend.new(root_stats_trades, {
      centerX: am5.p50,
      x: am5.p50
    }));

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root_stats_trades, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "date",
      }));

      series.columns.template.setAll({
        tooltipText: "[bold]{name}[/]\n{categoryX}: {valueY}",
        tooltipY: am5.percent(10)
      });
      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      // series.bullets.push(function () {
      //   return am5.Bullet.new(root_stats_trades, {
      //     sprite: am5.Label.new(root_stats_trades, {
      //       text: "{valueY}",
      //       // fill: root_stats_trades.interfaceColors.get("alternativeText"),
      //       centerY: am5.p50,
      //       centerX: am5.p50,
      //       populateText: true
      //     })
      //   });
      // });

      legend.data.push(series);
    }

    makeSeries("Fee", "fee_calcUSD");
    makeSeries("Profit", "profit_USD");


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root_stats_trades.dispose()
    }
  }, [ProfitComplete])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_stacked')) {
      return
    }

    let root_profit_stacked = am5.Root.new('chart_profit_stacked')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_stacked.setThemes([
      am5themes_Animated.new(root_profit_stacked)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_profit_stacked.container.children.push(am5xy.XYChart.new(root_profit_stacked, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
    }));

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root_profit_stacked, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      let lastUSD = 0
      let lastFee = 0
      for (let i = ProfitComplete.stats_daily.length - 1; i >= 0; i--) {
        let dataentry = {
          date: ProfitComplete.stats_daily[i]._id,
          // profit: ProfitComplete.stats_daily[i].profit,
          profit_USD: ProfitComplete.stats_daily[i].profit_USD - ProfitComplete.stats_daily[i].fee_calcUSD + lastUSD,
          // profit_USD: ProfitComplete.stats_daily[i].profit_USD + lastUSD,
          // profit_percent: ProfitComplete.stats_daily[i].profit_percent,
          // count: ProfitComplete.stats_daily[i].count,
          fee_calcUSD: ProfitComplete.stats_daily[i].fee_calcUSD + lastFee,
        }
        lastUSD = lastUSD + ProfitComplete.stats_daily[i].profit_USD - ProfitComplete.stats_daily[i].fee_calcUSD
        lastFee = lastFee + ProfitComplete.stats_daily[i].fee_calcUSD
        data.push(dataentry)
      }
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root_profit_stacked, {
      categoryField: "date",
      startLocation: 0.5,
      endLocation: 0.5,
      renderer: am5xy.AxisRendererX.new(root_profit_stacked, {}),
      tooltip: am5.Tooltip.new(root_profit_stacked, {})
    }));

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root_profit_stacked, {
      renderer: am5xy.AxisRendererY.new(root_profit_stacked, {})
    }));

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(am5.Legend.new(root_profit_stacked, {
      centerX: am5.p50,
      x: am5.p50,
      y: am5.percent(98),
    }));

    // Chart title
    let title = chart.plotContainer.children.push(am5.Label.new(root_profit_stacked, {
      text: "Accumulative profit in $ by crazybot.io",
      fontSize: 20,
      fontWeight: "400",
      x: am5.p50,
      centerX: am5.p50
    }))

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(name, field) {
      let series = chart.series.push(am5xy.LineSeries.new(root_profit_stacked, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        stacked: true,
        valueYField: field,
        categoryXField: "date",
        tooltip: am5.Tooltip.new(root_profit_stacked, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
        })
      }));

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
      });

      series.data.setAll(data);
      series.appear(1000);

      legend.data.push(series);

    }

    createSeries("Fee", "fee_calcUSD");
    createSeries("Profit", "profit_USD");

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root_profit_stacked, {
      orientation: "horizontal"
    }));

    // // Create axis ranges
    // // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/axis-ranges/
    // let rangeDataItem = xAxis.makeDataItem({
    //   category: "2001",
    //   endCategory: "2003"
    // });
    //
    // let range = xAxis.createAxisRange(rangeDataItem);
    //
    // rangeDataItem.get("grid").setAll({
    //   stroke: am5.color(0x00ff33),
    //   strokeOpacity: 0.5,
    //   strokeDasharray: [3]
    // });
    //
    // rangeDataItem.get("axisFill").setAll({
    //   fill: am5.color(0x00ff33),
    //   fillOpacity: 0.1,
    //   visible: true
    // });
    //
    // rangeDataItem.get("label").setAll({
    //   inside: true,
    //   text: "Fines for speeding increased",
    //   rotation: 90,
    //   centerX: am5.p100,
    //   centerY: am5.p100,
    //   location: 0,
    //   paddingBottom: 10,
    //   paddingRight: 15
    // });
    //
    // let rangeDataItem2 = xAxis.makeDataItem({
    //   category: "2007"
    // });
    //
    // let range2 = xAxis.createAxisRange(rangeDataItem2);
    //
    // rangeDataItem2.get("grid").setAll({
    //   stroke: am5.color(0x00ff33),
    //   strokeOpacity: 1,
    //   strokeDasharray: [3]
    // });
    //
    // rangeDataItem2.get("axisFill").setAll({
    //   fill: am5.color(0x00ff33),
    //   fillOpacity: 0.1,
    //   visible: true
    // });
    //
    // rangeDataItem2.get("label").setAll({
    //   inside: true,
    //   text: "Motorcycle fee introduced",
    //   rotation: 90,
    //   centerX: am5.p100,
    //   centerY: am5.p100,
    //   location: 0,
    //   paddingBottom: 10,
    //   paddingRight: 15
    // });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

//     // Create axes
//     // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
//     let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root_profit_stacked, {
//       // maxDeviation: 0.3,
//       baseInterval: {
//         timeUnit: "day",
//         count: 1
//       },
//       renderer: am5xy.AxisRendererX.new(root_profit_stacked, {}),
//       tooltip: am5.Tooltip.new(root_profit_stacked, {})
//     }));
//
//     let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root_profit_stacked, {
//       renderer: am5xy.AxisRendererY.new(root_profit_stacked, {}),
//       // tooltip: am5.Tooltip.new(root_stats_trades, {})
//     }));
//
// // Add series
// // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
//     let series = chart.series.push(am5xy.LineSeries.new(root_profit_stacked, {
//       connect: true,
//       xAxis: xAxis,
//       yAxis: yAxis,
//       valueYField: "profit_USD",
//       valueXField: "date",
//       tooltip: am5.Tooltip.new(root_profit_stacked, {
//         labelText: "{valueY}"
//       })
//     }));
//
//     series.fills.template.setAll({
//       fillOpacity: 0.2,
//       visible: true
//     });
//
//     series.strokes.template.setAll({
//       strokeWidth: 2
//     });
//
//     // Chart title
//     let title = chart.plotContainer.children.push(am5.Label.new(root_profit_stacked, {
//       text: "Accumulative Profit in $",
//       fontSize: 20,
//       fontWeight: "400",
//       x: am5.p50,
//       centerX: am5.p50
//     }))
//
//     let data = []
//     if (ProfitComplete && ProfitComplete.stats_daily) {
//       let lastUSD = 0
//       for (let i = ProfitComplete.stats_daily.length - 1; i >= 0; i--) {
//         let dataentry = {
//           date: new Date(ProfitComplete.stats_daily[i]._id).getTime(),
//           profit: ProfitComplete.stats_daily[i].profit,
//           profit_USD: ProfitComplete.stats_daily[i].profit_USD + lastUSD,
//           profit_percent: ProfitComplete.stats_daily[i].profit_percent,
//           count: ProfitComplete.stats_daily[i].count,
//           fee_calcUSD: ProfitComplete.stats_daily[i].fee_calcUSD,
//         }
//         lastUSD = lastUSD + ProfitComplete.stats_daily[i].profit_USD
//         data.push(dataentry)
//       }
//     }
//
//     series.data.setAll(data);
//
//     // Make stuff animate on load
//     // https://www.amcharts.com/docs/v5/concepts/animations/
//     series.appear(1000);
//     chart.appear(1000, 100);

    return () => {
      root_profit_stacked.dispose()
    }
  }, [ProfitComplete])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_percent')) {
      return
    }

    let root_profit_percent = am5.Root.new('chart_profit_percent')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_percent.setThemes([
      am5themes_Animated.new(root_profit_percent)
    ]);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_percent.setThemes([
      am5themes_Animated.new(root_profit_percent)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_profit_percent.container.children.push(am5xy.XYChart.new(root_profit_percent, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
    }));

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root_profit_percent, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    // Chart title
    let title = chart.plotContainer.children.push(am5.Label.new(root_profit_percent, {
      text: "Profit in percent by crazybot.io",
      fontSize: 20,
      fontWeight: "400",
      x: am5.p50,
      centerX: am5.p50
    }))

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      for (let i = ProfitComplete.stats_daily.length - 1; i >= 0; i--) {
        let dataentry = {
          date: ProfitComplete.stats_daily[i]._id,
          // profit: ProfitComplete.stats_daily[i].profit,
          // profit_USD: ProfitComplete.stats_daily[i].profit_USD - ProfitComplete.stats_daily[i].fee_calcUSD,
          // profit_USD: ProfitComplete.stats_daily[i].profit_USD + lastUSD,
          profit_percent: ProfitComplete.stats_daily[i].profit_percent,
          // count: ProfitComplete.stats_daily[i].count,
          fee_calcUSD: ProfitComplete.stats_daily[i].fee_calcUSD,
        }
        data.push(dataentry)
      }
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root_profit_percent, {
      categoryField: "date",
      startLocation: 0.5,
      endLocation: 0.5,
      renderer: am5xy.AxisRendererX.new(root_profit_percent, {}),
      tooltip: am5.Tooltip.new(root_profit_percent, {})
    }));

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root_profit_percent, {
      renderer: am5xy.AxisRendererY.new(root_profit_percent, {})
    }));

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(am5.Legend.new(root_profit_percent, {
      centerX: am5.p50,
      x: am5.p50,
      y: am5.percent(98),
    }));

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(name, field) {
      let series = chart.series.push(am5xy.LineSeries.new(root_profit_percent, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        stacked: true,
        valueYField: field,
        categoryXField: "date",
        tooltip: am5.Tooltip.new(root_profit_percent, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryX}: {valueY}%"
        })
      }));

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
      });

      series.data.setAll(data);
      series.appear(1000);

      legend.data.push(series);

    }

    createSeries("Profit", "profit_percent");

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root_profit_percent, {
      orientation: "horizontal"
    }));

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root_profit_percent.dispose()
    }
  }, [ProfitComplete])

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chart_profit_count')) {
      return
    }

    let root_profit_count = am5.Root.new('chart_profit_count')

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_count.setThemes([
      am5themes_Animated.new(root_profit_count)
    ]);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root_profit_count.setThemes([
      am5themes_Animated.new(root_profit_count)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root_profit_count.container.children.push(am5xy.XYChart.new(root_profit_count, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
    }));

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root_profit_count, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    // Chart title
    let title = chart.plotContainer.children.push(am5.Label.new(root_profit_count, {
      text: "Count of deals by crazybot.io",
      fontSize: 20,
      fontWeight: "400",
      x: am5.p50,
      centerX: am5.p50
    }))

    let data = []
    if (ProfitComplete && ProfitComplete.stats_daily) {
      for (let i = ProfitComplete.stats_daily.length - 1; i >= 0; i--) {
        let dataentry = {
          date: ProfitComplete.stats_daily[i]._id,
          // profit: ProfitComplete.stats_daily[i].profit,
          // profit_USD: ProfitComplete.stats_daily[i].profit_USD - ProfitComplete.stats_daily[i].fee_calcUSD,
          // profit_USD: ProfitComplete.stats_daily[i].profit_USD + lastUSD,
          // profit_percent: ProfitComplete.stats_daily[i].profit_percent,
          count: ProfitComplete.stats_daily[i].count,
          // fee_calcUSD: ProfitComplete.stats_daily[i].fee_calcUSD,
        }
        data.push(dataentry)
      }
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root_profit_count, {
      categoryField: "date",
      startLocation: 0.5,
      endLocation: 0.5,
      renderer: am5xy.AxisRendererX.new(root_profit_count, {}),
      tooltip: am5.Tooltip.new(root_profit_count, {})
    }));

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root_profit_count, {
      renderer: am5xy.AxisRendererY.new(root_profit_count, {})
    }));

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(am5.Legend.new(root_profit_count, {
      centerX: am5.p50,
      x: am5.p50,
      y: am5.percent(98),
    }));

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(name, field) {
      let series = chart.series.push(am5xy.LineSeries.new(root_profit_count, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        stacked: true,
        valueYField: field,
        categoryXField: "date",
        tooltip: am5.Tooltip.new(root_profit_count, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
        })
      }));

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
      });

      series.data.setAll(data);
      series.appear(1000);

      legend.data.push(series);

    }

    createSeries("Deals", "count");

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root_profit_count, {
      orientation: "horizontal"
    }));

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root_profit_count.dispose()
    }
  }, [ProfitComplete])

  useEffect(() => {
    // console.log('useEffect', botData)
    // if (botData && botData.account_id) {
    //   getSymbols(botData.account_id).then((data) => setSymbols(data))
    // }
    if (botData && botData._id) {
      getProfitComplete(botData._id).then((data) => setProfitComplete(data))
    }
  }, [botData?.symbol])

  // Futures Check
  function FuturesCheck() {
    if (botData && botData.account_id && botData.symbol !== '') {
      // Check, if symbol has ":" in it
      let Symbol = botData.symbol
      if (Symbol.indexOf(':') !== -1) {
        setFutures(true)
      } else {
        setFutures(false)
      }
    }
  }

  useEffect(() => {
    FuturesCheck()
  }, [botData?.symbol, symbols]);

  // OBF:
  let AccountTypeAllowed = ['BinanceFuture', 'BybitFuture']
  if (botData && botData.type === 'dca') {
    AccountTypeAllowed = ['BinanceSpot', 'BybitSpot', 'BybitFuture']
  }

  // Set default Account Id, if not set
  if (botData && botData.account_id === '') {
    let Accounts = buildAccountOptions(accountData, AccountTypeAllowed, '', 'type')
    if (Accounts.length > 0) {
      botData.account_id = Accounts[0].value
      // botData.exchange = Accounts[0].exchange
      // Load Symbols
      getSymbols(botData.account_id).then((data) => setSymbols(data))
    }
  }

  const onModalSubmitHandler = (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)

    const sendData = {
      type: data.add_funds_type,
      amount: data.add_funds_amount,
      price: data.add_funds_price,
      side: data.add_funds_side,
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/smartbot/${id}/add_funds`
      let Method = 'POST'
      const response = fetch(RequestURL, {
        method: Method,
        body: JSON.stringify(sendData),
        headers: {
          Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })

      // Get JSON Response
      response.then((res) => res.json()).then((json) => {
        // console.log(json)
        if (json.status === 'ok') {
          setShowModalAddFunds(false)
          setformSubmitMsg(json)
          // Reload Bot Data
          // getBotData(id).then((data) => setBotData(data))
        } else {
          setformSubmitMsg(json)
          console.log(json)
        }
      })

    } catch (err) {
      console.log(err)
    }

  }

  let ProfitLossQuote = (ProfitComplete?.unrealized_profit ?? 0) + (ProfitComplete?.profit_current_quote ?? 0)

  function downloadCSV() {
    // Fetch data
    fetch(`${process.env.REACT_APP_API_URI}/smartbot/${id}/download/csv`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `crazybot_${id}.csv`,
        )

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }

  let selectedEvent = botData?.events.find(event => event.name === eventSelectItem);

  useEffect(() => {
    // if (botData && botData._id && botData.events && isNaN(eventSelectItem)) {
    if (botData && botData._id && botData?.events && botData?.events[0]?.name && eventSelectItem === null) {
      // Select first element:
      setEventSelectItem(botData.events[0].name)
    }
  }, [botData?.events]);

  useEffect(() => {
    if (eventSelectItem && selectedEvent) {
      // Select first element:
      setEventSelectIndicator(selectedEvent.data[0].name)
    }
  }, [eventSelectItem]);

  // useEffect(() => {
  //   // console.log('useEffect', botData)
  //   // if (botData && botData.account_id) {
  //   //   getSymbols(botData.account_id).then((data) => setSymbols(data))
  //   // }
  //   selectedEvent = botData?.events.find(event => event.name === eventSelectItem);
  // }, [botData?.events.find(event => event.name === eventSelectItem)])

  // console.log(getLimitsFromSymbol(botData.symbol)?.limits?.leverage?.max ?? 1)


  // console.log('eventSelectIndicator', eventSelectIndicator)
  // console.log('eventSelectItem', eventSelectItem)
  // botData.events[eventSelectItem]?.data[eventSelectIndicator]?.length
  let selectedEventIndex = botData?.events.findIndex(event => event.name === eventSelectItem);
  let selectedEventIndicatorIndex = botData?.events[selectedEventIndex]?.data.findIndex(event => event.name === eventSelectIndicator);

  // console.log('botDataTest', botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length)

  // console.log(botData)

  const layoutParameters = {
    // cols: 12,
    // rowHeight: 30,
    // width: 100%:
    // width:
  }
  const Defaultlayout = {
    // lg: [{"w": 24,"h":3,"x":0,"y":0,"i":"user-fee","minW":2,"minH":1,"moved":false,"static":false}],
    lg: [{
      "w": 24,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "smartbot-accumulativeprofit",
      "minW": 4,
      "minH": 1,
      "moved": false,
      "static": false
    }],
    md: [{
      "w": 20,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "smartbot-accumulativeprofit",
      "minW": 4,
      "minH": 1,
      "moved": false,
      "static": false
    }],
    sm: [{
      "w": 12,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "smartbot-accumulativeprofit",
      "minW": 4,
      "minH": 1,
      "moved": false,
      "static": false
    }],
    xs: [{
      "w": 8,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "smartbot-accumulativeprofit",
      "minW": 4,
      "minH": 1,
      "moved": false,
      "static": false
    }],
    xxs: [{
      "w": 4,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "smartbot-accumulativeprofit",
      "minW": 4,
      "minH": 1,
      "moved": false,
      "static": false
    }],
  }

  function removeIndicator(studyId) {
    if (activeChart && studyId) {
      activeChart.removeEntity(studyId)
    }
  }

  return (
    botData && (
      <div>
        {/*{showModalAddFunds ? (*/}
        {/*  <Modal*/}
        {/*    dismissFn={() => setShowModalAddFunds(false)}*/}
        {/*    // submitFn={submitModalAddFunds}*/}
        {/*    showFooter={true}*/}
        {/*    header="Add funds"*/}
        {/*    formSubmit="addFundsSubmit"*/}
        {/*    // showLoader={showModalLoader}*/}
        {/*  >*/}
        {/*    <form id="addFundsSubmit" onSubmit={onModalSubmitHandler}>*/}
        {/*      <Fieldset legend="Add Funds" columns={1} dismiss={false}>*/}
        {/*        <InputField*/}
        {/*          type="hidden"*/}
        {/*          name="id"*/}
        {/*          value={botData ? botData._id : 'add'}*/}
        {/*        />*/}
        {/*        <Select*/}
        {/*          name="add_funds_type"*/}
        {/*          label="Grid Type"*/}
        {/*          value={addFunds.type}*/}
        {/*          options={[*/}
        {/*            {name: 'Manual', value: 'manual'},*/}
        {/*          ]}*/}
        {/*          tooltip="There are different types:<br>1. Manual: You can add funds manually without a buy action on the exchange."*/}
        {/*          onChange={function (evt) {*/}
        {/*            const options = evt.target.options*/}
        {/*            const index = options.selectedIndex*/}
        {/*            setAddFunds((prevState) => ({*/}
        {/*              ...prevState,*/}
        {/*              type: options[index].value,*/}
        {/*            }))*/}
        {/*          }}*/}
        {/*        />*/}
        {/*        <InputField*/}
        {/*          type="text"*/}
        {/*          name="add_funds_amount"*/}
        {/*          label="Amount"*/}
        {/*          value={addFunds.amount}*/}
        {/*          pattern="numeric"*/}
        {/*          precision={Precision.base}*/}
        {/*          uom={getSymbolBase(botData.symbol)}*/}
        {/*          onChange={function (evt) {*/}
        {/*            setAddFunds(function (prevState) {*/}
        {/*              return {*/}
        {/*                ...prevState,*/}
        {/*                amount: evt.target.value,*/}
        {/*              }*/}
        {/*            })*/}
        {/*          }}*/}
        {/*        />*/}
        {/*        <InputField*/}
        {/*          type="text"*/}
        {/*          name="add_funds_price"*/}
        {/*          label="Price"*/}
        {/*          value={addFunds.price}*/}
        {/*          pattern="numeric"*/}
        {/*          precision={Precision.quote}*/}
        {/*          uom={getSymbolQuote(botData.symbol)}*/}
        {/*          onChange={function (evt) {*/}
        {/*            setAddFunds(function (prevState) {*/}
        {/*              return {*/}
        {/*                ...prevState,*/}
        {/*                price: evt.target.value,*/}
        {/*              }*/}
        {/*            })*/}
        {/*          }}*/}
        {/*        />*/}
        {/*        <Select*/}
        {/*          name="add_funds_side"*/}
        {/*          label="Side"*/}
        {/*          value={addFunds.side}*/}
        {/*          options={[*/}
        {/*            {name: 'Buy', value: 'buy'},*/}
        {/*            {name: 'Sell', value: 'sell'},*/}
        {/*          ]}*/}
        {/*          tooltip="Buy order or sell order"*/}
        {/*          onChange={function (evt) {*/}
        {/*            const options = evt.target.options*/}
        {/*            const index = options.selectedIndex*/}
        {/*            setAddFunds((prevState) => ({*/}
        {/*              ...prevState,*/}
        {/*              side: options[index].value,*/}
        {/*            }))*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      </Fieldset>*/}
        {/*    </form>*/}
        {/*  </Modal>*/}
        {/*) : null}*/}
        <form style={{padding: '0 1.6rem', margin: '0 auto'}} onSubmit={onSubmitForm}>
          <CryptoWidget id={botData._id} type='smartbot'/>
          {/*<div className="row">Profit/Loss: {parseFloat(ProfitLossQuote).toFixed(Precision.quote)} {getSymbolQuote(botData.symbol)}</div>*/}
          <Fieldset
            legend="TradingView"
            columns={1}
            dismiss={true}
            collapsed={true}
            height={800}
          >
            <div className="col-9 pl-0 graph">
              <div id="tv_chart_container" className="TVChartContainer" style={{height: '800px'}}/>
            </div>
            {/*<div className="col-9 pl-0 graph">*/}
            {/*  <div id="tv_chart_container" className="TVChartContainer" style={{height: '800px'}}/>*/}
            {/*</div>*/}
            {/*<iframe>*/}
            {/*<AdvancedChart*/}
            {/*  widgetProps={{*/}
            {/*    theme: 'dark',*/}
            {/*    interval: '1m',*/}
            {/*    // range: '60',*/}
            {/*    style: "1",*/}
            {/*    symbol:*/}
            {/*      getExchangeFromAccount(accountData, botData.account_id) +*/}
            {/*      ':' +*/}
            {/*      (botData.symbol*/}
            {/*        ? botData.symbol.replace('/', '').replace('-', '')*/}
            {/*        : ''),*/}
            {/*    studies: [*/}
            {/*      // "MACD@tv-basicstudies",*/}
            {/*      // "StochasticRSI@tv-basicstudies",*/}
            {/*      "RSI@tv-basicstudies",*/}
            {/*      // "TripleEMA@tv-basicstudies"*/}
            {/*    ],*/}

            {/*  }}*/}
            {/*/>*/}
            {/*</iframe>*/}
          </Fieldset>
          <Fieldset
            legend="Stats"
            columns={1}
            dismiss={true}
            collapsed={true}
          >
            <div>
              Total Profit:
              ${parseFloat(ProfitComplete?.profitUSD ?? 0).toFixed(2)} ({parseFloat(ProfitComplete?.profit ?? 0).toFixed(Precision?.quote)}) {getSymbolQuote(botData.symbol)} |
              per Day: {parseFloat(ProfitComplete?.profit_percent_day ?? 0).toFixed(4)} %
              | 7 days avg: {parseFloat(ProfitComplete?.profit_percent_day_7 ?? 0).toFixed(4)} %
              | 30 days avg: {parseFloat(ProfitComplete?.profit_percent_day_30 ?? 0).toFixed(4)} %
              | Unrealized: {parseFloat(ProfitComplete?.unrealized_profitUSD ?? 0).toFixed(2)} USD
              | Overall: {parseFloat(ProfitComplete?.total_profitUSD ?? 0).toFixed(2)} USD
            </div>
            <div
              id="chart_profit_USD"
              style={{width: '100%', height: '500px'}}
            ></div>
            <div
              id="chart_profit_stacked"
              style={{width: '100%', height: '500px'}}
            ></div>
            <div
              id="chart_profit_percent"
              style={{width: '100%', height: '500px'}}
            ></div>
            <div
              id="chart_profit_count"
              style={{width: '100%', height: '500px'}}
            ></div>
            <CBElements GridLayoutParameter={layoutParameters} ElementNames={[
              'smartbot-accumulativeprofit',
            ]} Prefix="smartbotdetail" DefaultGridLayout={Defaultlayout} Data={{botData: botData}}/>
          </Fieldset>
          <Fieldset
            legend="Assistent"
            columns={1}
            dismiss={true}
            collapsed={true}
          >
            <div>Bot ID: {botData._id}</div>
            <div>Orders on exchange: {botData.lastsub_id}</div>
            <div>Max Quote
              Amount: {parseFloat(botData.max_quote_amount ?? 0).toFixed(Precision.quote)} {getSymbolQuote(botData.symbol)}</div>
            <div>Drawdown: {parseFloat(botData.max_drawdown ?? 0).toFixed(2)} %</div>
            <div>Exchange Quote
              Total: {parseFloat(botData.stats?.exchange_balance_total ?? 0).toFixed(Precision.quote)} {getSymbolQuote(botData.symbol)}</div>
            <div>Exchange Quote
              Free: {parseFloat(botData.stats?.exchange_balance_free ?? 0).toFixed(Precision.quote)} {getSymbolQuote(botData.symbol)}</div>

          </Fieldset>

          <Fieldset legend="Account Settings" columns={3} dismiss={true}>
            {formSubmitMsg ? <div>{JSON.stringify(formSubmitMsg)}</div> : null}
            <InputField type="hidden" name="id" value={botData ? botData._id : 'add'}/>
            <Select
              name="account_id"
              label="Account"
              options={buildAccountOptions(accountData, AccountTypeAllowed, '', 'type')}
              value={botData.account_id}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  account_id: options[index].value,
                }))
              }}
            />
            <Select
              name="type"
              label="Smart Type"
              value={botData.type}
              options={[
                // {name: 'Smart Classic', value: 'classic'},
                {name: 'DCA', value: 'dca'},
                {name: 'OBF', value: 'obf'},
              ]}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  type: options[index].value,
                }))
              }}
            />
            <Select
              name="symbol"
              label="Symbol"
              value={botData.symbol}
              options={mapSymbols(symbols)}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  symbol: options[index].value,
                }))
              }}
            />
            <InputField
              type="text"
              name="name"
              label="Name"
              pattern="alphanumericwithspaceandminus"
              value={botData.name ? botData.name : 'Bot'}
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    name: evt.target.value,
                  }
                })
              }}
            />
            <Select
              name="strategy"
              label="Strategy"
              value={botData.strategy}
              disabled={!Futures}
              options={[
                {name: 'Long', value: 'long'},
                {name: 'Short', value: 'short'},
              ]}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  strategy: options[index].value,
                }))
              }}
            />
            <InputField
              type="text"
              name="leverage"
              label="Leverage"
              pattern="integer"
              disabled={!Futures}
              minvalue={getLimitsFromSymbol(botData.symbol)?.limits?.leverage?.min ?? 1}
              maxvalue={getLimitsFromSymbol(botData.symbol)?.limits?.leverage?.max ?? 1}
              value={botData.leverage ? botData.leverage : '1'}
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    leverage: evt.target.value,
                  }
                })
              }}
            />
            <InputField
              type="switch"
              name="hedgemode"
              label="Hedge Mode"
              checked={botData.hedgemode}
              disabled={!Futures}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  hedgemode: evt.target.checked,
                }))
              }}
            />
            <Select
              name="marginmode"
              label="Margin Mode"
              value={botData.marginmode}
              disabled={!Futures}
              options={[
                {name: 'Cross', value: 'cross'},
                {name: 'Isolated', value: 'isolated'},
              ]}
              onChange={function (evt) {
                const options = evt.target.options
                const index = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  marginmode: options[index].value,
                }))
              }}
            />

          </Fieldset>
          {((botData.type ?? 'classic') === 'obf') ? (
            <Fieldset legend="Base Order" columns={3} dismiss={true}>
              <InputField
                type="text"
                name="amount"
                label="Amount"
                pattern='numeric'
                precision={Precision.quote}
                value={botData.amount}
                // value={parseFloat(botData.amount ? botData.amount : '0').toFixed(
                //   Precision.quote
                // )}
                uom={botData.amountuom}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    amount: evt.target.value,
                  }))
                }}
              />
              <Select
                name="amounttype"
                label="Base Order Type"
                value={botData.amounttype}
                options={[
                  {name: 'Quote', value: 'quote'},
                  {name: '% Quote', value: 'percent'},
                  {name: '% Walletrisk per trade', value: 'walletpertrade'},
                ]}
                onChange={function (evt) {
                  const options = evt.target.options
                  const index = options.selectedIndex
                  setBotData((prevState) => ({
                    ...prevState,
                    amounttype: options[index].value,
                  }))
                  botData.amountuom = (options[index].value === 'percent' || options[index].value === 'walletpertrade') ? '%' : getSymbolQuote(botData.symbol)
                }}
              />
              <InputField
                type="text"
                name="leverage"
                label="Leverage"
                value={parseInt(botData.leverage ? botData.leverage : '-1')}
                pattern='integer'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    leverage: evt.target.value,
                  }))
                }}
              />
              <Select
                name="timeframe"
                label="Timeframe"
                gridcolumns="3"
                value={botData.timeframe}
                options={[
                  {name: '15s', value: '15s'},
                  {name: '1m', value: '1m'},
                  {name: '5m', value: '5m'},
                  {name: '15m', value: '15m'},
                  {name: '1h', value: '1h'},
                  {name: '4h', value: '4h'},
                  {name: '1d', value: '1d'},
                  {name: '3d', value: '3d'},
                  {name: '1w', value: '1w'},
                  {name: '2w', value: '2w'},
                  {name: '1M', value: '1M'},
                ]}
              />
              <InputField
                type="switch"
                name="bo.rsi.enabled"
                label="RSI Enable"
                checked={botData.bo.rsi.enabled}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      rsi: {
                        ...prevState.bo.rsi,
                        enabled: evt.target.checked,
                      }
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="bo.rsi.length"
                label="RSI Length"
                value={parseInt(botData.bo.rsi.length)}
                pattern='integer'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      rsi: {
                        ...prevState.bo.rsi,
                        length: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <Select
                name="bo.rsi.timeframe"
                label="RSI Timeframe"
                value={botData.bo.rsi.timeframe}
                options={[
                  {name: '15s', value: '15s'},
                  {name: '1m', value: '1m'},
                  {name: '5m', value: '5m'},
                  {name: '15m', value: '15m'},
                  {name: '1h', value: '1h'},
                  {name: '4h', value: '4h'},
                  {name: '1d', value: '1d'},
                  {name: '3d', value: '3d'},
                  {name: '1w', value: '1w'},
                  {name: '2w', value: '2w'},
                  {name: '1M', value: '1M'},
                ]}
              />
              <InputField
                type="switch"
                name="bo.ema.enabled"
                label="EMA Enable"
                checked={botData.bo.ema.enabled}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      ema: {
                        ...prevState.bo.ema,
                        enabled: evt.target.checked,
                      },
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="bo.ema.length"
                label="EMA Length"
                value={parseInt(botData.bo.ema.length)}
                pattern='integer'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      ema: {
                        ...prevState.bo.ema,
                        length: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <Select
                name="bo.ema.timeframe"
                label="EMA Timeframe"
                value={botData.bo.ema.timeframe}
                options={[
                  {name: '15s', value: '15s'},
                  {name: '1m', value: '1m'},
                  {name: '5m', value: '5m'},
                  {name: '15m', value: '15m'},
                  {name: '1h', value: '1h'},
                  {name: '4h', value: '4h'},
                  {name: '1d', value: '1d'},
                  {name: '3d', value: '3d'},
                  {name: '1w', value: '1w'},
                  {name: '2w', value: '2w'},
                  {name: '1M', value: '1M'},
                ]}
              />
              <InputField
                type="text"
                name="bo.candlecount"
                label="Candle Count"
                value={parseInt(botData.bo.candlecount)}
                pattern='integer'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      candlecount: evt.target.value,
                    },
                  }))
                }}
              />
              <InputField
                type="switch"
                name="bo.resistanceandsupport"
                label="Resistance and Support"
                checked={botData.bo.resistanceandsupport}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      resistanceandsupport: evt.target.checked,
                    },
                  }))
                }}
              />
              <InputField
                type="switch"
                name="bo.engulfing_only"
                label="Engulfing Only"
                checked={botData.bo.engulfing_only}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    bo: {
                      ...prevState.bo,
                      engulfing_only: evt.target.checked,
                    },
                  }))
                }}
              />

            </Fieldset>
          ) : null}
          {((botData.type ?? 'classic') === 'obf') ? (
            <Fieldset legend="Take Profit" columns={3} dismiss={true}>
              <Select
                type="switch"
                name="tp.type"
                label="Take Profit Type"
                value={botData.tp.type}
                options={[
                  {name: 'Percent', value: 'percent'},
                  {name: 'ATR', value: 'atr'},
                  {name: 'RR', value: 'rr'},
                ]}
              />
              <InputField
                type="text"
                name="tp.percent"
                label="Percent"
                uom={'%'}
                // value={parseFloat(botData.tp.percent)}
                value={botData.tp.percent}
                pattern='numeric'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    tp: {
                      ...prevState.tp,
                      percent: evt.target.value,
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="tp.rr.multiplier"
                label="RR Factor"
                value={botData.tp.rr.multiplier}
                pattern='numeric'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    tp: {
                      ...prevState.tp,
                      rr: {
                        ...prevState.tp.rr,
                        multiplier: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="tp.atr.length"
                label="ATR Length"
                value={parseInt(botData.tp.atr.length)}
                pattern='integer'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    tp: {
                      ...prevState.tp,
                      atr: {
                        ...prevState.tp.atr,
                        length: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <Select
                name="tp.atr.timeframe"
                label="ATR Timeframe"
                value={botData.tp.atr.timeframe}
                options={[
                  {name: '15s', value: '15s'},
                  {name: '1m', value: '1m'},
                  {name: '5m', value: '5m'},
                  {name: '15m', value: '15m'},
                  {name: '1h', value: '1h'},
                  {name: '4h', value: '4h'},
                  {name: '1d', value: '1d'},
                  {name: '3d', value: '3d'},
                  {name: '1w', value: '1w'},
                  {name: '2w', value: '2w'},
                  {name: '1M', value: '1M'},
                ]}
              />
              <InputField
                type="text"
                name="tp.atr.multiplier"
                label="ATR Factor"
                value={botData.tp.atr.multiplier}
                pattern='numeric'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    tp: {
                      ...prevState.tp,
                      atr: {
                        ...prevState.tp.atr,
                        multiplier: evt.target.value,
                      },
                    },
                  }))
                }}
              />
            </Fieldset>
          ) : null}
          {((botData.type ?? 'classic') === 'obf') ? (
            <Fieldset legend="Stop Loss" columns={3} dismiss={true}>
              <Select
                type="switch"
                name="sl.type"
                label="Stop Loss Type"
                value={botData.sl.type}
                options={[
                  {name: 'Percent', value: 'percent'},
                  {name: 'ATR', value: 'atr'},
                ]}
              />
              <InputField
                type="text"
                name="sl.percent"
                label="Percent"
                uom={'%'}
                value={botData.sl.percent}
                pattern='numeric'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    sl: {
                      ...prevState.sl,
                      percent: evt.target.value,
                    },
                  }))
                }}
              />
              <InputField
                type="text"
                name="sl.atr.length"
                label="ATR Length"
                value={parseInt(botData.sl.atr.length)}
                pattern='integer'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    sl: {
                      ...prevState.sl,
                      atr: {
                        ...prevState.sl.atr,
                        length: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <Select
                name="sl.atr.timeframe"
                label="ATR Timeframe"
                value={botData.sl.atr.timeframe}
                options={[
                  {name: '15s', value: '15s'},
                  {name: '1m', value: '1m'},
                  {name: '5m', value: '5m'},
                  {name: '15m', value: '15m'},
                  {name: '1h', value: '1h'},
                  {name: '4h', value: '4h'},
                  {name: '1d', value: '1d'},
                  {name: '3d', value: '3d'},
                  {name: '1w', value: '1w'},
                  {name: '2w', value: '2w'},
                  {name: '1M', value: '1M'},
                ]}
              />
              <InputField
                type="text"
                name="sl.atr.multiplier"
                label="ATR Multiplier"
                value={botData.sl.atr.multiplier}
                pattern='numeric'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    sl: {
                      ...prevState.sl,
                      atr: {
                        ...prevState.sl.atr,
                        multiplier: evt.target.value,
                      },
                    },
                  }))
                }}
              />
            </Fieldset>
          ) : null}
          {((botData.type ?? 'classic') === 'dca') ? (
            <Fieldset legend="DCA" columns={1} dismiss={true}>
              <InputField
                type="text"
                name="DCAMaxActiveSO"
                label="Max Active SO"
                value={botData.DCAMaxActiveSO}
                gridcolumns={1}
                pattern='numeric'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    DCAMaxActiveSO: evt.target.value,
                  }))
                }}
              />
              <InputField
                type="text"
                name="DCAMaxSO"
                label="Max SO"
                value={botData.DCAMaxSO}
                gridcolumns={1}
                pattern='numeric'
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    DCAMaxSO: evt.target.value,
                  }))
                }}
              />
              <InputField
                type="text"
                name="DCARunningTimes"
                label="Running Times"
                value={botData.DCARunningTimes}
                gridcolumns={1}
                pattern='numeric'
                tooltip={'How many times the bot will run the DCA strategy. -1 means unlimited. 0 means stop after the bot is finished.'}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    DCARunningTimes: evt.target.value,
                  }))
                }}
              />
              {/*{botData.dcaorders.map((DCAOrder, DCAOrderIndex) => (*/}
              {/*  <div key={DCAOrderIndex}>*/}
              {/*    <Select*/}
              {/*      name={`dcaorder.${DCAOrderIndex}.dcatype`}*/}
              {/*      label={DCAOrder.name + ' Type'}*/}
              {/*      value={DCAOrder.type}*/}
              {/*      options={[*/}
              {/*        {name: 'Market Trailing', value: 'markettrailing'},*/}
              {/*      ]}*/}
              {/*      onChange={function (evt) {*/}
              {/*        const options = evt.target.options*/}
              {/*        const index = options.selectedIndex*/}
              {/*        setBotData((prevState) => ({*/}
              {/*          ...prevState,*/}
              {/*          dcaorders: prevState.dcaorders.map((dcaorder, index) => {*/}
              {/*            if (index === DCAOrderIndex) {*/}
              {/*              return {*/}
              {/*                ...dcaorder,*/}
              {/*                type: options[index].value,*/}
              {/*              }*/}
              {/*            }*/}
              {/*            return dcaorder*/}
              {/*          })*/}
              {/*        }))*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    <Select*/}
              {/*      name={`dcaorder.${DCAOrderIndex}.amounttype`}*/}
              {/*      label={DCAOrder.name + ' Amount Type'}*/}
              {/*      value={DCAOrder.amounttype}*/}
              {/*      options={[*/}
              {/*        {name: 'Base', value: 'base'},*/}
              {/*        {name: 'Quote', value: 'quote'},*/}
              {/*      ]}*/}
              {/*      onChange={function (evt) {*/}
              {/*        const options = evt.target.options*/}
              {/*        const index = options.selectedIndex*/}
              {/*        setBotData((prevState) => ({*/}
              {/*          ...prevState,*/}
              {/*          dcaorders: prevState.dcaorders.map((dcaorder, index) => {*/}
              {/*            if (index === DCAOrderIndex) {*/}
              {/*              return {*/}
              {/*                ...dcaorder,*/}
              {/*                amounttype: options[index].value,*/}
              {/*              }*/}
              {/*            }*/}
              {/*            return dcaorder*/}
              {/*          })*/}
              {/*        }))*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    <InputField*/}
              {/*      type="text"*/}
              {/*      name={`dcaorder.${DCAOrderIndex}.amount`}*/}
              {/*      label={DCAOrder.name + ' Amount'}*/}
              {/*      value={DCAOrder.amount}*/}
              {/*      pattern='numeric'*/}
              {/*      precision={(DCAOrder.amounttype ?? 'base') === 'base' ? Precision.base : Precision.quote}*/}
              {/*      uom={botData.dcaorders[DCAOrderIndex].amounttype === 'base' ? getSymbolBase(botData.symbol) : getSymbolQuote(botData.symbol)}*/}
              {/*      // uom={(DCAOrder.amounttype ?? 'base') === 'base' ? getSymbolBase(botData.symbol) : getSymbolQuote(botData.symbol)}*/}
              {/*      onChange={function (evt) {*/}
              {/*        setBotData((prevState) => ({*/}
              {/*          ...prevState,*/}
              {/*          dcaorders: prevState.dcaorders.map((dcaorder, index) => {*/}
              {/*            if (index === DCAOrderIndex) {*/}
              {/*              return {*/}
              {/*                ...dcaorder,*/}
              {/*                amount: evt.target.value,*/}
              {/*              }*/}
              {/*            }*/}
              {/*            return dcaorder*/}
              {/*          })*/}
              {/*        }))*/}
              {/*      }}*/}
              {/*    />*/}

              {/*  </div>*/}
              {/*))}*/}

              <InputField
                type="textarea"
                name="dcaorders"
                label="DCA Orders (JSON)"
                height="300px"
                // Object to String
                value={JSON.stringify(botData.dcaorders, null, 2)}
                // value={JSON.stringify(botData.process, null, 2)}
                onChange={function (evt) {
                  setBotData(function (prevState) {
                    // is it really json?
                    try {
                      return {
                        ...prevState,
                        dcaorders: JSON.parse(evt.target.value),
                      }
                    } catch (e) {
                      return {
                        ...prevState,
                        dcaorders: prevState.dcaorders,
                      }
                    }
                  })
                }}
              />

            </Fieldset>
          ) : null}
          {((botData.type ?? 'classic') === 'dca') ? (
            <Fieldset columns={3} legend="Events" dismiss={true}>
              <Select
                name="eventselectitem"
                label="Event"
                gridcolumns="2"
                value={eventSelectItem === null ? "" : eventSelectItem}
                options={botData?.events.map(event => ({name: event.name, value: event.value}))}
                onChange={function (evt) {
                  const options = evt.target.options
                  const index = options.selectedIndex
                  setEventSelectItem(options[index].value)
                }}
              />
              <Select
                name="eventselectindicator"
                label="Indicator"
                value={eventSelectIndicator === null ? "" : eventSelectIndicator}
                options={selectedEvent?.data.map(item => ({name: item.name, value: item.name}))}
                // options={selectedEvent?.data.map((item, index) => ({
                //   name: item.name,
                //   value: item.name
                // }))}
                onChange={function (evt) {
                  const options = evt.target.options
                  const index = options.selectedIndex
                  setEventSelectIndicator(options[index].value)
                  // setEventSelectItem(options[index].value)
                  // setEventSelectItem(index)
                }}
              />
              {/* Line element */}
              <div style={{
                position: 'absolute', // or 'relative' depending on your layout
                top: '150px', // Adjust this value to set the vertical position
                left: '0',
                width: '100%',
                height: '1px', // Adjust the thickness of the line
                backgroundColor: 'grey' // Adjust the color of the line
              }}></div>


              {/*value={botData.events[eventSelectItem]?.data[eventSelectIndicator]?.position}*/}

              {eventSelectIndicator === 'emacross' ? (
                <InputField
                  type="text"
                  name="events.emacross.length1"
                  label="Length 1"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length1)}
                  pattern='integer'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  length1: evt.target.value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}

              {eventSelectIndicator === 'emacross' ? (
                <Select
                  name="events.emacross.condition"
                  label="Condition"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.condition}
                  options={[
                    {name: 'Greater or Equal (>=)', value: '>='},
                    {name: 'Less or Equal (<=)', value: '<='},
                    {name: 'Cross Under', value: 'crossunder'},
                    {name: 'Cross Over', value: 'crossover'},

                  ]}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  condition: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }
                  }
                />
              ) : null}

              {eventSelectIndicator === 'emacross' ? (
                <InputField
                  type="text"
                  name="events.emacross.length2"
                  label="Length 2"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length2)}
                  pattern='integer'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  length2: evt.target.value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'emacross' ? (
                <Select
                  name="events.emacross.timeframe"
                  label="Timeframe"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.timeframe}
                  options={[
                    {name: '1m', value: '1m'},
                    {name: '5m', value: '5m'},
                    {name: '15m', value: '15m'},
                    {name: '1h', value: '1h'},
                    {name: '4h', value: '4h'},
                    {name: '1d', value: '1d'},
                    {name: '3d', value: '3d'},
                    {name: '1w', value: '1w'},
                    {name: '2w', value: '2w'},
                    {name: '1M', value: '1M'},
                  ]}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  timeframe: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'emacross' ? (
                <InputField
                  type="text"
                  name="events.emacross.candle"
                  label="Candle"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.candle ?? 0)}
                  pattern='integer'
                  minvalue={0}
                  maxvalue={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length}
                  tooltip={'0 is the current candle (live), 1 is the previous candle (first closed), etc.'}
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  candle: parseInt(evt.target.value),
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}

              {/*only if RSI selected*/}
              {eventSelectIndicator === 'rsi' ? (
                <InputField
                  type="text"
                  name="events.rsi.length"
                  label="RSI Length"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length)}
                  pattern='integer'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  length: evt.target.value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'rsi' ? (
                <Select
                  name="events.rsi.timeframe"
                  label="RSI Timeframe"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.timeframe}
                  options={[
                    {name: '1m', value: '1m'},
                    {name: '5m', value: '5m'},
                    {name: '15m', value: '15m'},
                    {name: '1h', value: '1h'},
                    {name: '4h', value: '4h'},
                    {name: '1d', value: '1d'},
                    {name: '3d', value: '3d'},
                    {name: '1w', value: '1w'},
                    {name: '2w', value: '2w'},
                    {name: '1M', value: '1M'},
                  ]}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  timeframe: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'rsi' ? (
                <Select
                  name="events.rsi.condition"
                  label="RSI Condition"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.condition}
                  options={[
                    {name: 'Greater or Equal (>=)', value: '>='},
                    {name: 'Less or Equal (<=)', value: '<='},
                    {name: 'Cross Under', value: 'crossunder'},
                    {name: 'Cross Over', value: 'crossover'},

                  ]}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  condition: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }
                  }
                />
              ) : null}
              {eventSelectIndicator === 'rsi' ? (
                <InputField
                  type="text"
                  name="events.rsi.value"
                  label="Value"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.value)}
                  pattern='numeric'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  value: evt.target.value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'rsi' ? (
                <InputField
                  type="text"
                  name="events.rsi.candle"
                  label="Candle"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.candle ?? 0)}
                  pattern='integer'
                  minvalue={0}
                  maxvalue={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length}
                  tooltip={'0 is the current candle (live), 1 is the previous candle (first closed), etc.'}
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  candle: parseInt(evt.target.value),
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}

              {eventSelectIndicator === 'signal' ? (
                <Select
                  name="events.signal.id"
                  label="Signal"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.id}
                  options={[
                    {
                      name: 'Latest (more coming)',
                      value: botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.id
                    },
                    // {name: 'Equal (=)', value: '=='},
                    // {name: 'Not Equal (!=)', value: '!='},
                    // {name: 'Greater (>)', value: '>'},
                    // {name: 'Greater or Equal (>=)', value: '>='},
                    // {name: 'Less (<)', value: '<'},
                    // {name: 'Less or Equal (<=)', value: '<='},
                  ]}
                  gridcolumns={2}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  id: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }
                  }
                />
              ) : null}
              {eventSelectIndicator === 'signal' ? (
                <Select
                  name="events.signal.timeframe"
                  label="Timeframe"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.timeframe}
                  options={[
                    {name: '1m', value: '1m'},
                    {name: '5m', value: '5m'},
                    {name: '15m', value: '15m'},
                    {name: '1h', value: '1h'},
                    {name: '4h', value: '4h'},
                    {name: '1d', value: '1d'},
                    {name: '3d', value: '3d'},
                    {name: '1w', value: '1w'},
                    {name: '2w', value: '2w'},
                    {name: '1M', value: '1M'},
                  ]}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  timeframe: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'signal' ? (
                <InputField
                  type="text"
                  name="events.rsi.variable"
                  label="Variable"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.variable}
                  pattern='string'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  variable: evt.target.value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'signal' ? (
                <Select
                  name="events.signal.condition"
                  label="Condition"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.condition}
                  options={[
                    {name: 'Equal (=)', value: '=='},
                    {name: 'Not Equal (!=)', value: '!='},
                    {name: 'Greater (>)', value: '>'},
                    {name: 'Greater or Equal (>=)', value: '>='},
                    {name: 'Less (<)', value: '<'},
                    {name: 'Less or Equal (<=)', value: '<='},
                  ]}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  condition: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }
                  }
                />
              ) : null}
              {eventSelectIndicator === 'signal' ? (
                <InputField
                  type="text"
                  name="events.signal.value"
                  label="Value"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.value}
                  pattern='string'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  value: evt.target.value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'atr' ? (
                <InputField
                  type="text"
                  name="events.atr.length"
                  label="ATR Length"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length)}
                  pattern='integer'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  length: parseInt(evt.target.value),
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'atr' ? (
                <Select
                  name="events.atr.timeframe"
                  label="Timeframe"
                  value={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.timeframe}
                  options={[
                    {name: '1m', value: '1m'},
                    {name: '5m', value: '5m'},
                    {name: '15m', value: '15m'},
                    {name: '1h', value: '1h'},
                    {name: '4h', value: '4h'},
                    {name: '1d', value: '1d'},
                    {name: '3d', value: '3d'},
                    {name: '1w', value: '1w'},
                    {name: '2w', value: '2w'},
                    {name: '1M', value: '1M'},
                  ]}
                  onChange={function (evt) {
                    const options = evt.target.options
                    const curindex = options.selectedIndex
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  timeframe: options[curindex].value,
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'atr' ? (
                <InputField
                  type="text"
                  name="events.atr.multiplier"
                  label="ATR Multiplier"
                  value={parseFloat(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.multiplier)}
                  pattern='numeric'
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  multiplier: parseFloat(evt.target.value),
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}
              {eventSelectIndicator === 'atr' ? (
                <InputField
                  type="text"
                  name="events.atr.candle"
                  label="ATR Candle"
                  value={parseInt(botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.candle ?? 0)}
                  pattern='integer'
                  minvalue={0}
                  maxvalue={botData?.events[selectedEventIndex]?.data[selectedEventIndicatorIndex]?.length}
                  onChange={function (evt) {
                    setBotData((prevState) => ({
                      ...prevState,
                      events: prevState.events.map((event, index) => {
                        if (index === selectedEventIndex) {
                          return {
                            ...event,
                            data: event.data.map((data, index) => {
                              if (index === selectedEventIndicatorIndex) {
                                return {
                                  ...data,
                                  candle: parseInt(evt.target.value),
                                }
                              }
                              return data
                            }),
                          }
                        }
                        return event
                      }),
                    }))
                  }}
                />
              ) : null}

              <InputField
                type="textarea"
                name="events"
                label="Events (JSON)"
                height="300px"
                gridcolumns={3}
                // Object to String
                value={JSON.stringify(botData.events, null, 2)}
                // value={JSON.stringify(botData.process, null, 2)}
                onChange={function (evt) {
                  setBotData(function (prevState) {
                    // is it really json?
                    try {
                      return {
                        ...prevState,
                        events: JSON.parse(evt.target.value),
                      }
                    } catch (e) {
                      return {
                        ...prevState,
                        events: prevState.events,
                      }
                    }
                  })
                }}
              />
              {/*<button onClick={function (evt) {*/}
              {/*  // Create a remove button for the indicator, but dont send a submit to the form:*/}
              {/*  evt.preventDefault()*/}
              {/*  // Remove Indicator, if we has a studyId*/}
              {/*  if (selectedEvent?.data[selectedEventIndicatorIndex]?.studyId) {*/}
              {/*    removeIndicator(selectedEvent?.data[selectedEventIndicatorIndex]?.studyId)*/}
              {/*  }*/}
              
              {/*  // Remove Data*/}
              {/*  setBotData((prevState) => ({*/}
              {/*    ...prevState,*/}
              {/*    events: prevState.events.map((event, index) => {*/}
              {/*      if (index === selectedEventIndex) {*/}
              {/*        return {*/}
              {/*          ...event,*/}
              {/*          data: event.data.filter((data, index) => {*/}
              {/*            return index !== selectedEventIndicatorIndex*/}
              {/*          }),*/}
              {/*        }*/}
              {/*      }*/}
              {/*      return event*/}
              {/*    }),*/}
              {/*  }))*/}
              
              {/*  // Refresh Indicator Field and set it to the first element:*/}
              {/*  setEventSelectIndicator(selectedEvent?.data[0]?.name)*/}
              
              
              {/*}} style={{marginTop: '1rem'}}>Remove Indicator*/}
              {/*</button>*/}

            </Fieldset>
          ) : null}
          {((botData.type ?? 'classic') === 'dca') ? (
            <Fieldset legend="Stop Loss" columns={3} dismiss={true}>
              <InputField
                type="switch"
                name="sl.0.enabled"
                label="Enabled"
                checked={botData.sl[0]?.enabled ?? false}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    sl: {
                      ...prevState.sl,
                      0: {
                        ...prevState.sl[0],
                        enabled: evt.target.checked,
                      },
                    },
                  }))
                }}
              />
              <Select
                type="switch"
                name="sl.0.pricetype"
                label="Stop Loss Type"
                value={botData.sl[0]?.pricetype}
                disabled={!botData.sl[0]?.enabled}
                options={[
                  {name: 'Base', value: 'base'},
                  // {name: 'ATR', value: 'atr'},
                ]}
              />
              <InputField
                type="text"
                name="sl.0.price"
                label="Price"
                // uom={'%'}
                value={botData.sl[0]?.price}
                pattern='numeric'
                disabled={!botData.sl[0]?.enabled}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    sl: {
                      ...prevState.sl,
                      0: {
                        ...prevState.sl[0],
                        price: evt.target.value,
                      },
                    },
                  }))
                }}
              />
              <Select
                type="switch"
                name="sl.0.side"
                label="Side"
                value={botData.sl[0]?.side}
                disabled={!botData.sl[0]?.enabled}
                options={[
                  {name: 'Sell', value: 'sell'},
                  {name: 'Buy', value: 'buy'},
                ]}
              />
              <Select
                type="switch"
                name="sl.0.amounttype"
                label="Amount Type"
                value={botData.sl[0]?.amounttype}
                disabled={!botData.sl[0]?.enabled}
                options={[
                  {name: 'Percent', value: 'percent'},
                ]}
              />
              <InputField
                type="text"
                name="sl.0.amount"
                label="Amount"
                uom={botData.sl[0]?.amounttype === 'percent' ? '%' : getSymbolQuote(botData.symbol)}
                value={botData.sl[0]?.amount}
                pattern='numeric'
                disabled={!botData.sl[0]?.enabled}
                minvalue={0}
                maxvalue={100}
                onChange={function (evt) {
                  setBotData((prevState) => ({
                    ...prevState,
                    sl: {
                      ...prevState.sl,
                      0: {
                        ...prevState.sl[0],
                        amount: evt.target.value,
                      },
                    },
                  }))
                }}
              />
            </Fieldset>
          ) : null}
          <Fieldset legend="Note" columns={1} dismiss={true} collapsed={true}>
            <InputField
              type="textarea"
              name="note"
              label="Note"
              height="200px"
              maxLength={10000}
              gridcolumns={1}
              // Object to String
              value={botData.note}
              // value={JSON.stringify(botData.process, null, 2)}
              onChange={function (evt) {
                setBotData(function (prevState) {
                  return {
                    ...prevState,
                    note: evt.target.value,
                  }
                })
              }}
            />
          </Fieldset>
          <Fieldset legend="Advanced" columns={2} dismiss={true}>
            <InputField
              type="switch"
              name="dryrun"
              label="Dry Run"
              checked={botData.dryrun}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  dryrun: evt.target.checked,
                }))
              }}
            />
            <InputField
              type="text"
              name="cycledelay"
              label="Cycle Delay"
              uom={'s'}
              value={parseInt(botData.cycledelay)}
              pattern='integer'
              minvalue={30}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  cycledelay: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="AllowOtherClientOrderId"
              label="AllowOtherClientOrderId"
              checked={botData.AllowOtherClientOrderId}
              tooltip={"Don't use it, if you don't know what it is."}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  AllowOtherClientOrderId: evt.target.checked,
                }))
              }}
            />
          </Fieldset>

          <InputField type="hidden" name="id" value={id}/>
          {BacktestActive ? (
            <button type="button" onClick={() => navigate('/backtest/' + backtestId)}>Backtest</button>
            ) : (
            <button type="submit">Save</button>
          )}
        </form>

        {/* External Link for the api page to open the log */}
        <button onClick={() => window.open('https://api.crazybot.io/v1/ws/log/console/smartbot-' + botData._id)}>Open
          Log
        </button>
        {/*<button*/}
        {/*  onClick={function (evt) {*/}
        {/*    setShowModalAddFunds(true)*/}
        {/*    openModal({*/}
        {/*      title: 'Add Funds',*/}
        {/*      content: <form id="addFundsSubmit" onSubmit={onModalSubmitHandler}>*/}
        {/*        <Fieldset legend="Add Funds" columns={1} dismiss={false}>*/}
        {/*          <InputField*/}
        {/*            type="hidden"*/}
        {/*            name="id"*/}
        {/*            value={botData ? botData._id : 'add'}*/}
        {/*          />*/}
        {/*          <Select*/}
        {/*            name="add_funds_type"*/}
        {/*            label="Grid Type"*/}
        {/*            value={addFunds.type}*/}
        {/*            options={[*/}
        {/*              {name: 'Manual', value: 'manual'},*/}
        {/*            ]}*/}
        {/*            tooltip="There are different types:<br>1. Manual: You can add funds manually without a buy action on the exchange."*/}
        {/*            onChange={function (evt) {*/}
        {/*              const options = evt.target.options*/}
        {/*              const index = options.selectedIndex*/}
        {/*              setAddFunds((prevState) => ({*/}
        {/*                ...prevState,*/}
        {/*                type: options[index].value,*/}
        {/*              }))*/}
        {/*            }}*/}
        {/*          />*/}
        {/*          <InputField*/}
        {/*            type="text"*/}
        {/*            name="add_funds_amount"*/}
        {/*            label="Amount"*/}
        {/*            value={addFunds.amount}*/}
        {/*            pattern="numeric"*/}
        {/*            precision={Precision.base}*/}
        {/*            uom={getSymbolBase(botData.symbol)}*/}
        {/*            onChange={function (evt) {*/}
        {/*              setAddFunds(function (prevState) {*/}
        {/*                return {*/}
        {/*                  ...prevState,*/}
        {/*                  amount: evt.target.value,*/}
        {/*                }*/}
        {/*              })*/}
        {/*            }}*/}
        {/*          />*/}
        {/*          <InputField*/}
        {/*            type="text"*/}
        {/*            name="add_funds_price"*/}
        {/*            label="Price"*/}
        {/*            value={addFunds.price}*/}
        {/*            pattern="numeric"*/}
        {/*            precision={Precision.quote}*/}
        {/*            uom={getSymbolQuote(botData.symbol)}*/}
        {/*            onChange={function (evt) {*/}
        {/*              setAddFunds(function (prevState) {*/}
        {/*                return {*/}
        {/*                  ...prevState,*/}
        {/*                  price: evt.target.value,*/}
        {/*                }*/}
        {/*              })*/}
        {/*            }}*/}
        {/*          />*/}
        {/*          <Select*/}
        {/*            name="add_funds_side"*/}
        {/*            label="Side"*/}
        {/*            value={addFunds.side}*/}
        {/*            options={[*/}
        {/*              {name: 'Buy', value: 'buy'},*/}
        {/*              {name: 'Sell', value: 'sell'},*/}
        {/*            ]}*/}
        {/*            tooltip="Buy order or sell order"*/}
        {/*            onChange={function (evt) {*/}
        {/*              const options = evt.target.options*/}
        {/*              const index = options.selectedIndex*/}
        {/*              setAddFunds((prevState) => ({*/}
        {/*                ...prevState,*/}
        {/*                side: options[index].value,*/}
        {/*              }))*/}
        {/*            }}*/}
        {/*          />*/}
        {/*        </Fieldset>*/}
        {/*      </form>*/}
        {/*      ,*/}
        {/*      showSubmitButton: true,*/}
        {/*      showDismissButton: true,*/}
        {/*      showFooter: true,*/}
        {/*      formSubmit: "addFundsSubmit",*/}
        {/*      submitFn: function () {*/}
        {/*        setShowModalAddFunds(false)*/}
        {/*      },*/}
        {/*      dismissFn: function () {*/}
        {/*        setShowModalAddFunds(false)*/}
        {/*      }*/}
        {/*    })*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Add Funds*/}
        {/*</button>*/}
        <button
          onClick={function (evt) {
            downloadCSV()
          }}
        >
          Download CSV
        </button>
        <button
          onClick={function (evt) {
            navigate('/backtest/add/' + botData._id)
          }}
          >Create Backtest
        </button>



      </div>
    )
  )
}

export default SmartbotDetailPage
