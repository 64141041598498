import React, {useContext, useEffect, useState} from 'react';
import Fieldset from '../../EditFields/Fieldset/Fieldset'
import InputField from '../../EditFields/Input/InputField'
import Select from '../../EditFields/Select/Select'
import tokenfromLS from "../../../utils/tokenFromLS";
import {useNavigate, useParams} from 'react-router-dom'
import {GlobalContext} from "../../../state";
import {useFetchBacktestId} from "../../../hooks/useFetchBacktest";
import {getSymbolQuote} from "../../../utils/symbol";
import CBApi from "../../../utils/datafeed/CBApi";
import CBElements from "../../Containers/CBElements";
import {useModal} from "../../Modal/Modal";

const BacktestDetailPage = () => {
  let params = useParams();

  let id = params.id ?? 'add'
  const [botData, setBotData] = useFetchBacktestId(id)
  if (params?.bot_id) {
    botData.bot_id = params.bot_id
  }
  const [formSubmitMsg, setformSubmitMsg] = useState(null)
  // const [accountData] = useFetchAccount()
  const navigate = useNavigate();

  // Global state
  const globalState = useContext(GlobalContext)
  const {openModal} = useModal();

  const [ProfitComplete, setProfitComplete] = useState([])

  function getProfitComplete(botId, bottype) {
    const resource = `${bottype}/${botId}/profitcomplete`
    const params = {
    }
    return CBApi.fetch(resource, 'GET', params)
  }

  useEffect(() => {
    if (botData?.backtestbot?._id) {
      getProfitComplete(botData?.backtestbot?._id, 'backtest').then(result => setProfitComplete(result.data));
    }
  }, [botData?.backtestbot?._id])

  function getPrecisionFromSymbol(aSymbol) {
    let ReturnSymbol = {
      base: 8,
      quote: 8,
      amount: 0,
      price: 0,
    }
    // if (
    //   symbols &&
    //   symbols.length &&
    //   symbols.length > 0 &&
    //   aSymbol &&
    //   aSymbol !== ''
    // ) {
    //   symbols.forEach((item) => {
    //     if (item.symbol === aSymbol && item.precision) {
    //       ReturnSymbol.base = (item.precision.base ? item.precision.base : ReturnSymbol.base)
    //       ReturnSymbol.quote = (item.precision.quote ? item.precision.quote : ReturnSymbol.quote)
    //       ReturnSymbol.amount = (item.precision.amount ? item.precision.amount : ReturnSymbol.amount)
    //       ReturnSymbol.price = (item.precision.price ? item.precision.price : ReturnSymbol.price)
    //     }
    //   })
    // }
    // console.log('getPrecisionFromSymbol', ReturnSymbol)
    return ReturnSymbol
  }
  const Precision = getPrecisionFromSymbol(botData ? botData.symbol : '')

  const onSubmitForm = async (evt) => {
    evt.preventDefault()
    setformSubmitMsg(null)
    const formData = new FormData(evt.target)

    let data = Object.fromEntries(formData)

    // Run Backtest
    if (evt.nativeEvent.submitter.name === 'run') {
      data.status = 'new'
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/backtest/${id}`
      let Method = 'PUT'
      if (id === 'add') {
        RequestURL = `${process.env.REACT_APP_API_URI}/backtest`
        Method = 'ADD'
        delete data.id
      }
      const response = await fetch(
        RequestURL,
        {
          method: Method,
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )

      const json = await response.json()
      if (json.status === 'ok') {
        navigate('/backtest');
      } else {
        openModal({
          content: 'Error: ' + (json.error || ' ') + (JSON.stringify(json?.data) || ' ') + (JSON.stringify(json?.error_description) || ' ' + JSON.stringify(json?.message) || ' '),
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        });
      }

    } catch (err) {
      openModal({
        content: 'Error: ' + err,
        showDismissButton: true,
        dismissButtonTxt: 'Close',
        showFooter: true,
      });
    }
  }

  const ViewReadOnly = (botData === null || botData.user_id === null)

  const layoutParameters = {
    // cols: 12,
    // rowHeight: 30,
    // width: 100%:
    // width:
  }
  const Defaultlayout = {
    // lg: [{"w": 24,"h":3,"x":0,"y":0,"i":"user-fee","minW":2,"minH":1,"moved":false,"static":false}],
    lg: [{"w": 24, "h": 3, "x": 0, "y": 0, "i": "smartbot-accumulativeprofit", "minW": 4, "minH": 1, "moved": false, "static": false}],
    md: [{"w": 20, "h": 3, "x": 0, "y": 0, "i": "smartbot-accumulativeprofit", "minW": 4, "minH": 1, "moved": false, "static": false}],
    sm: [{"w": 12, "h": 3, "x": 0, "y": 0, "i": "smartbot-accumulativeprofit", "minW": 4, "minH": 1, "moved": false, "static": false}],
    xs: [{"w": 8, "h": 3, "x": 0, "y": 0, "i": "smartbot-accumulativeprofit", "minW": 4, "minH": 1, "moved": false, "static": false}],
    xxs: [{"w": 4, "h": 3, "x": 0, "y": 0, "i": "smartbot-accumulativeprofit", "minW": 4, "minH": 1, "moved": false, "static": false}],
  }

  return (
    botData && (
      <div>
        <form
          style={{padding: '0 4rem', margin: '0 auto'}}
          onSubmit={onSubmitForm}
        >
          <Fieldset legend="Backtest Settings" columns={2} dismiss={true}>
            {formSubmitMsg ? <div>{JSON.stringify(formSubmitMsg)}</div> : null}
            {/*<Select*/}
            {/*  name="account_id"*/}
            {/*  label="Account"*/}
            {/*  options={buildAccountOptions(accountData, '3commas')}*/}
            {/*  value={BacktestData.account_id}*/}
            {/*  // onChange={function (evt) {*/}
            {/*  //   const options = evt.target.options*/}
            {/*  //   const index = options.selectedIndex*/}
            {/*  //   getSymbols(options[index].value).then((data) =>*/}
            {/*  //     setSymbols(data)*/}
            {/*  //   )*/}
            {/*  // }}*/}
            {/*/>*/}
            <InputField
              type="text"
              name="bot_id"
              label="Smartbot ID"
              value={botData?.bot_id}
              pattern='objectid'
              disabled={ViewReadOnly || botData?.bottestbot?._id === null}
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  bot_id: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="withoutoptimization"
              label="Without Optimization"
              checked={botData.withoutoptimization}
              disabled={ViewReadOnly}
            />
            <Select
              name="timeframe"
              label="Timeframe"
              gridcolumns={2}
              value={botData?.timeframe ?? '1h'}
              options={[
                {name: '1m', value: '1m'},
                {name: '5m', value: '5m'},
                {name: '15m', value: '15m'},
                {name: '1h', value: '1h'},
                {name: '4h', value: '4h'},
                {name: '1d', value: '1d'},
                {name: '3d', value: '3d'},
                {name: '1w', value: '1w'},
                {name: '2w', value: '2w'},
                {name: '1M', value: '1M'},
              ]}
              disabled={ViewReadOnly}
              onChange={function (evt) {
                const options = evt.target.options
                const curindex = options.selectedIndex
                setBotData((prevState) => ({
                  ...prevState,
                  timeframe: options[curindex].value,
                }))
              }}
            />
            <InputField
              type="text"
              name="startdate"
              label="Start Date"
              disabled={ViewReadOnly}
              value={botData?.startdate ?? '2024-01-01 00:00:00'}
              pattern='^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$'
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  startdate: evt.target.value,
                }))
              }}
            />
            <InputField
              type="text"
              name="stopdate"
              label="End Date"
              disabled={ViewReadOnly}
              value={botData?.stopdate ?? '2024-01-02 23:59:59'}
              pattern='^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$'
              onChange={function (evt) {
                setBotData((prevState) => ({
                  ...prevState,
                  stopdate: evt.target.value,
                }))
              }}
            />
            <InputField
              type="switch"
              name="publicview"
              label="Public View"
              checked={botData.publicview}
              disabled={ViewReadOnly}
            />

            <InputField type="hidden" name="id" value={id}/>
          </Fieldset>
          {/*<Fieldset*/}
          {/*  legend="Stats"*/}
          {/*  columns={1}*/}
          {/*  dismiss={true}*/}
          {/*  collapsed={true}*/}
          {/*>*/}
            <CBElements GridLayoutParameter={layoutParameters} ElementNames={[
              'smartbot-accumulativeprofit',
            ]} Prefix="backtest" DefaultGridLayout={Defaultlayout} Data={{botData: botData?.backtestbot}} />
            <div>
              Total Profit:
              ${parseFloat(ProfitComplete?.profitUSD ?? 0).toFixed(2)} ({parseFloat(ProfitComplete?.profit ?? 0).toFixed(Precision?.quote)}) {getSymbolQuote(botData.symbol)} |
              per Day: {parseFloat(ProfitComplete?.profit_percent_day ?? 0).toFixed(4)} %
              | 7 days avg: {parseFloat(ProfitComplete?.profit_percent_day_7 ?? 0).toFixed(4)} %
              | 30 days avg: {parseFloat(ProfitComplete?.profit_percent_day_30 ?? 0).toFixed(4)} %
              | Unrealized: {parseFloat(ProfitComplete?.unrealized_profitUSD ?? 0).toFixed(2)} USD
              | Overall: {parseFloat(ProfitComplete?.total_profitUSD ?? 0).toFixed(2)} USD
            </div>
            {/*<div*/}
            {/*  id="chart_profit_USD"*/}
            {/*  style={{width: '100%', height: '500px'}}*/}
            {/*></div>*/}
            {/*<div*/}
            {/*  id="chart_profit_stacked"*/}
            {/*  style={{width: '100%', height: '500px'}}*/}
            {/*></div>*/}
            {/*<div*/}
            {/*  id="chart_profit_percent"*/}
            {/*  style={{width: '100%', height: '500px'}}*/}
            {/*></div>*/}
            {/*<div*/}
            {/*  id="chart_profit_count"*/}
            {/*  style={{width: '100%', height: '500px'}}*/}
            {/*></div>*/}
          {/*</Fieldset>*/}
          {!ViewReadOnly ? (
            <button type="submit">Save</button>
          ) : (
            <button type="button" onClick={() => navigate('/backtest')}>Close</button>
          )}

          {((botData?.backtestbot?._id ?? null) !== null) ? (
            <button type="button" onClick={() => navigate('/backtest/' + id + '/smartbot/' + botData?.backtestbot._id)}>Open Bot Settings</button>
          ) : ((botData?.bot_id ?? null) !== null ? (
            <button type="button" onClick={() => navigate('/smartbot/' + botData.bot_id)}>Open Smartbot</button>
          ) : null)
          }

          {!ViewReadOnly ? (
            <button type="submit" name='run'>Save and Run</button>
          ) : null}
        </form>
      </div>
    )
  )
}

export default BacktestDetailPage
